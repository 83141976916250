import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import SelectSubject from "../components/StudentDashboard/SelectSubject";

import { useAuthState } from "../context";
import { getSubjects4Client } from "../context";
import Background from "../images/subjectsbackground.svg"

export default function StudentDashboard() {
  const [{ user }, dispatch] = useAuthState()
  const categoryid = localStorage.getItem("categoryid");

  useEffect(() => {
    getSubjects4Client(dispatch, localStorage.getItem("categoryid"))
  }, [categoryid, dispatch])
  if (categoryid === null || categoryid === 'undefined' || user === "undefined" || user === null) return <Redirect to="/stud-dashboard" />;
  return (
    <div style={{backgroundImage: `url("${Background}")`, backgroundSize: "cover"}}>
      <SelectSubject />
    </div>
  );
}
