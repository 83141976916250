import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography, FormHelperText } from "@material-ui/core";
import { deleteTest,  useAuthState } from "../../context";
import SubjectFilter from "./SubjectFilter";
import CategoryFilter from "./CategoryFilter";
import UpdateTest from "./UpdateTest";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import DeleteItem from "./DeleteItem";
import Visibility from "./Visibility";
import { visibleTest } from "../../context/Actions/VisibleActions";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    background: "#fff",
  },
  header: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
  },
  field: {
    textTransform: "capitalize",
  },
});

export default function ViewTests(props) {
  const [{ tests, subjects, loading }, dispatch] = useAuthState();
  const classes = useStyles();
  const clickHandler = (testid, testname, createdBy) => {
    localStorage.setItem("testid", testid);
    localStorage.setItem("testname", testname);
    localStorage.setItem("createdBy", createdBy);
  };
  if (loading)
    return (
      <>
        <Skeleton variant="rect" height={30} />
        <br />
        <Skeleton variant="rect" height={165} />
      </>
    );
  return (
    <>
      <div className="d-flex justify-content-between text-right">
        <Typography variant="h4" id="tableTitle" component="div">
          {props.toggle === 1 ? "" : "Tests"}
        </Typography>
        {subjects.filter((subject) => subject.isActive === 1).length !== 0 ? (
          <div className="d-flex justify-content-between text-right">
          <div><SubjectFilter />
            <FormHelperText>Subject</FormHelperText>
          </div>
          <div className="ml-2 mr-2"><CategoryFilter />
            <FormHelperText>category</FormHelperText>
          </div>
          </div>
        ) : null}
      </div>

      {tests.filter((test) => test.isActive === 1).length === 0 ? (
        <div className="mb-5 pb-2">
          <h4 className="text-center text-secondary border border-info p-3 mt-5">
            No Tests Available!
          </h4>
        </div>
      ) : (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>
                  Name of the test
                </TableCell>
                <TableCell className={classes.header}>Subject</TableCell>
                <TableCell className={classes.header}>Total</TableCell>

                <TableCell className={classes.header}>Dur (mins)</TableCell>
                <TableCell className={classes.header}>
                  Start Date&Time
                </TableCell>
                <TableCell className={classes.header}>End Date&Time</TableCell>
                <TableCell className={classes.header}>Questions</TableCell>
                <TableCell className={classes.header}>
                  Submission
                </TableCell>
                <TableCell className={classes.header}>Edit Test</TableCell>
                <TableCell className={classes.header}>Del Test</TableCell>
                <TableCell className={classes.header}>Copy</TableCell>
                <TableCell className={classes.header}>Visible</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tests
                .filter((test) => test.isActive === 1 && test.categoryType == props.category)
                .map(
                  ({
                    testname,
                    testid,
                    totalMarks,
                    subname,
                    subid,
                    testtime,
                    createdBy,
                    startTestTime,
                    endTestTime,
                    isVisible,
                    categoryName,
                    categoryid,
                    categoryType
                  }) =>
                      <TableRow key={testid}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.field}
                        >
                          {testname}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.field}
                        >
                          {subname}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {totalMarks}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {testtime}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {startTestTime}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {endTestTime}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Link to={{ pathname: "/add-questions" }}>
                            <button
                              type="button"
                              className="btn btn-info btn-sm"
                              onClick={() =>
                                clickHandler(testid, testname, createdBy)
                              }
                            >
                              Questions
                            </button>
                          </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Link to={{ pathname: "/results" }}>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                clickHandler(testid, testname, createdBy)
                              }
                            >
                              Submissions
                            </button>
                          </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <UpdateTest
                            info={0}
                            createdBy={createdBy}
                            total={totalMarks}
                            initialTestName={testname}
                            initialSubid={subid}
                            initialTestTime={testtime}
                            testid={testid}
                            initialSubName={subname}
                            fun={props.updateTest}
                            initialStartTime={startTestTime}
                            initialEndTime={endTestTime}
                            initialCategoryid={categoryid}
                            initialCategoryName={categoryName}
                            initialCategoryType={categoryType}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="mr-4">
                            <DeleteItem
                              deleteFun={() => deleteTest(dispatch, testid)}
                              item={testname}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="mr-4">
                            <UpdateTest
                              info={1}
                              total={totalMarks}
                              createdBy={createdBy}
                              initialTestName={testname}
                              initialSubid={subid}
                              initialTestTime={testtime}
                              testid={testid}
                              fun={props.duplicateTest}
                              initialSubName={subname}
                              initialStartTime={startTestTime}
                              initialEndTime={endTestTime}
                              initialCategoryid={categoryid}
                              initialCategoryName={categoryName}
                              initialCategoryType={categoryType}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="mr-4">
                            <Visibility
                              id={testid}
                              isVisible={isVisible}
                              updateFun={visibleTest}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
