import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthState } from "../../context";
import { Link, Redirect } from "react-router-dom";
import { Loading } from "../Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 285,
    height: 320,
    background: "#fff",
    boxShadow: "7px 8px 20px #bcc0c0",
    "&:hover": {
      boxShadow: "7px 8px 30px #bcc0c0",
      cursor: "pointer",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    padding: theme.spacing(1),
    fontWidth: 900,
    textTransform: "capitalize",
  },
  main: {
    marginTop: 50,
  },
  data: {
    marginTop: -6,
    textAlign: "center",
  },
}));

export const TestCard = () => {
  const [{ tests, loading }, dispatch] = useAuthState();
  const userLevel = localStorage.getItem("user.level")
  try {
    var retrievedData = localStorage.getItem("testsGiven");
    var testsGiven = JSON.parse(retrievedData);
  } catch (err) {
    console.log(err);
  }

  useEffect(()=>{
    dispatch({
      type: "GET_QUESTIONS",
      questions: [],
    })
  },[])
  const handleTestClick = (testid, testtime) => {
    localStorage.setItem(
      "testObject",
      JSON.stringify({ testid: testid, timer: testtime })
    );
    // window.location.reload();

  };

  const handleSubmissionClick = (testid) => {
    localStorage.setItem("testid", testid);
  };
  const classes = useStyles();
  const today = new Date();
  const current = today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
  if (testsGiven === undefined || testsGiven === null)
    return <Redirect to="/stud-dashboard" />;
  return (
    <>
      <div className={classes.main}>
        <hr />
        {loading ? (
          <Loading />
        ) : (
          <div className="container">
            <div className="row">
              {tests.filter((test) => test.isActive === 1 && test.isVisible).length === 0 ? (
                <h4 className="col-sm-12 text-center text-secondary border border-info p-3">
                  Tests Not Available!
                </h4>
              ) : (
                <h3 className="col-sm-12 text-center card-header text-light">
                  Tests
                </h3>
              )}
              {tests
                .filter((test) => test.isActive === 1 && test.isVisible)
                .map(
                  ({
                    testid,
                    testname,
                    totalMarks,
                    subname,
                    testtime,
                    startTestTime,
                    endTestTime,
                  }) => (
                    <div className="col-sm-4 mb-4 mt-4" key={testid}>
                      <div className="py-4 mx-auto p-2">
                        <div className=" card test-card bg-white p-5 shadow rounded h-100">
                          {new Date(current) >= new Date(endTestTime) ? (
                            <div className="custom-icon bg-danger shadow-sm">
                              <i className="fa fa-calendar-times-o text-white"></i>
                            </div>
                          ) : (
                            <div className="custom-icon bg-info shadow-sm">
                              <i className="fa fa-calendar-check-o text-white"></i>
                            </div>
                          )}
                          <div className="icon">
                            <i className="fa fa-lg fa-clock-o text-warning "></i>
                            &nbsp; {testtime} mins
                          </div>
                          <div className="my-2 test-title">
                            <h6 className="mb-0 mt-3">{testname}</h6>
                            <span className="small text-center text-muted my-4">
                              {subname}
                            </span>
                          </div>

                          {startTestTime !== undefined && endTestTime !== undefined ? (
                            <div className="mt-2 text-center">
                              <p className="small">
                                <strong>Starts At: </strong>
                                {startTestTime}
                              </p>
                              <p className="small">
                                <strong>Ends At: </strong>
                                {endTestTime}
                              </p>
                            </div>
                          ) : <div className="mt-5 pt-5"></div>}
                          <p className="small text-muted my-2">
                            Marks:{totalMarks}
                          </p>
                          <footer className="footer pt-4 mt-3 border-top">
                            {testsGiven &&
                            testsGiven.find((value) => value === testid) ? (
                              <>
                              {endTestTime !== undefined ? 
                              new Date(current) >= new Date(endTestTime) ? (
                                userLevel === "2" ?
                                  <Link to="/submission">
                                    <button
                                      onClick={() =>
                                        handleSubmissionClick(testid)
                                      }
                                      className="btn btn-outline-info btn-block"
                                    >
                                      View Submission
                                    </button>
                                  </Link>
                                  :
                                  <Link to="">
                                    <button
                                      className="btn btn-outline-info btn-block"
                                    >
                                      Submitted
                                    </button>
                                  </Link>
                                  
                                ) : (
                                  <Link to="/subject-test-view">
                                    <button className="btn btn-outline-secondary btn-block">
                                      OnGoing
                                    </button>
                                  </Link>
                                )
                              :
                              userLevel === "2" ?
                                  <Link to="/submission">
                                    <button
                                      onClick={() =>
                                        handleSubmissionClick(testid)
                                      }
                                      className="btn btn-outline-info btn-block"
                                    >
                                      View Submission
                                    </button>
                                  </Link>
                                  :
                                    <button
                                      className="btn btn-outline-info btn-block"
                                    >
                                      Submitted
                                    </button>
                              }
                                
                              </>
                            ) : (
                              <>
                                {endTestTime !== undefined ? 
                                new Date(current) <= new Date(startTestTime) ||
                                new Date(current) >= new Date(endTestTime) ? (
                                  <Link to="/subject-test-view">
                                    {new Date(current) <=
                                    new Date(startTestTime) ? (
                                      <button className="btn btn-outline-secondary btn-block ">
                                        Upcoming
                                      </button>
                                    ) : (
                                      <button className="btn btn-outline-danger btn-block ">
                                        Expired
                                      </button>
                                    )}
                                  </Link>
                                ) : (
                                  <Link
                                    to="/test"
                                    onClick={() => {
                                      localStorage.setItem("testid", testid);
                                      localStorage.setItem(
                                        "testname",
                                        testname
                                      );
                                      localStorage.setItem(
                                        "totalMarks",
                                        totalMarks
                                      );
                                      localStorage.setItem("timer", testtime);
                                      localStorage.setItem(
                                        "startTestTime",
                                        startTestTime
                                      );
                                      localStorage.setItem(
                                        "endTestTime",
                                        endTestTime
                                      );
                                    }}
                                  >
                                    <button className="btn btn-outline-info btn-block ">
                                      Start
                                    </button>
                                  </Link>
                                )
                                :
                                <Link
                                    to="/test"
                                    onClick={() => {
                                      localStorage.setItem("testid", testid);
                                      localStorage.setItem(
                                        "testname",
                                        testname
                                      );
                                      localStorage.setItem(
                                        "totalMarks",
                                        totalMarks
                                      );
                                      localStorage.setItem("timer", testtime);
                                      localStorage.setItem(
                                        "startTestTime",
                                        startTestTime
                                      );
                                      localStorage.setItem(
                                        "endTestTime",
                                        endTestTime
                                      );
                                    }}
                                  >
                                    <button className="btn btn-outline-info btn-block ">
                                      Start
                                    </button>
                                  </Link>
                                }
                                
                              </>
                            )}
                          </footer>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};