import React , { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Typography, Paper } from '@material-ui/core';
import {  duplicateTest, updateTest, useAuthState } from '../../context';
import Skeleton from '@material-ui/lab/Skeleton';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import UpdateUser from "./UpdateUser"
import ViewTests from '../SubAdminDashboard/ViewTests'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    background:"#fff"
  },
  header: {
    fontWeight: 'bold'
  },
  link: {
    textDecoration: 'none',
  },
  btns:{
    className: "side_nav_item",
    "&:hover": {
      backgroundColor: "#7facea",
    },
    "&:active": {
      backgroundColor: "#777cea",
    },
  },
  tab: {
    backgroundColor: "#ffd",
  },
  paper: {
    marginTop: '1.5rem',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function ViewClients({clients}) {
  

  const [{loading}] = useAuthState()
  const classes = useStyles();
  const [toggle, setToggle] = useState(1)
  const theme = useTheme();
  const [value, setValue] = React.useState(parseInt(localStorage.getItem("categoryIndex")) || 0);

  const handleChange = (event, newValue) => {
    localStorage.setItem("categoryIndex", newValue)
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  if (loading) return (<><Skeleton variant="rect" height={30}/><br/><Skeleton variant="rect" height={165} /></>)
  const getFileName = () => {
    let d = new Date()
    let dformat = `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`;
    return "Student's_Details_" + dformat + ".csv";
  }
  return (
    <>
    <div className="d-flex justify-content-between align-items-center">
      <Typography variant="h4" id="tableTitle" component="div">
      
      <Button  onClick={()=>{setToggle(2)}} className={classes.btns}> <AssignmentIndIcon/>  Clients</Button> / <Button className={classes.btns}   onClick={()=>{setToggle(1)}}> <FolderSharedIcon/>  Tests</Button> 
      </Typography>
      
      <Typography variant="h4" id="tableTitle" component="div">{toggle === 2 ? "Clients": "Tests"} </Typography>
    </div>
    {toggle  === 2 ? (<TableContainer>
      <CSVLink
                  className=" text-left text-decoration-none"
                  data={clients.map(({fname, lname, contact, email, testsGiven,  uid, erpid, college, dob, universityNumber, gender, address}) => ({
                    First_Name: fname,
                    Last_Name: lname,
                    Contact: contact,
                    Email:email,
                    ERP_ID:erpid,
                    College:college,
                    DOB: dob,
                    University_Roll_Number: universityNumber,
                    Gender:gender,
                    Address:address
                  }))}
                  filename={getFileName()}
                  target="_blank"
                >
                  Download User List
                </CSVLink>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell className={classes.header}>S.N.</TableCell>

            <TableCell className={classes.header}>First Name</TableCell>
            <TableCell className={classes.header}>Last Name</TableCell>
            <TableCell className={classes.header}>Contact</TableCell>
            <TableCell className={classes.header}>Email</TableCell>
            <TableCell className={classes.header}>Password</TableCell>
            <TableCell className={classes.header}>Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map(({fname, lname, contact, email, password, testsGiven,  uid}, index) => (
            <TableRow key={uid}>
            <TableCell component="th" scope="row">{index+1}</TableCell>

              <TableCell component="th" scope="row">
                {fname}
              </TableCell>
              <TableCell component="th" scope="row">{lname}</TableCell>
              <TableCell component="th" scope="row">{contact}</TableCell>
              <TableCell component="th" scope="row">
              {email}
              </TableCell>
              <TableCell component="th" scope="row">
            {password}
              </TableCell>
              <TableCell component="th" scope="row">
              <UpdateUser  ifname={fname} ilname={lname} iuid={uid} icontact={contact} iemail={email} itestsGiven={testsGiven} key={uid}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>):
      <Paper className={classes.paper}>
              <div className={classes.tab}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="Tests"
                  >
                    <Tab label="College" {...a11yProps(0)} />
                    <Tab label="Others" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                  <ViewTests toggle={toggle} updateTest={updateTest} duplicateTest={duplicateTest} category="2"/>  
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
       <ViewTests toggle={toggle} updateTest={updateTest} duplicateTest={duplicateTest} category="4"/>  
                  </TabPanel>
                </SwipeableViews>
              </div>
            </Paper>
      
      
      
       
       
       }
    </>
  );
}
