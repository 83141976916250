import axios from "axios";
import { readUser4Client } from "./ClientActions";
const baseURL = "https://tapapi.shivalikcollege.edu.in";


export const subAdminLogin = async (dispatch, { username, password }) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    username,
    password,
  };
  try {
    const res = await axios.post(`${baseURL}/login`, body, config);
    if(res.data[0].level === 1){
       dispatch({
          type: 'LOGIN_SUCCESS',
          payload: res.data,
        })
      readUser4Client(dispatch)
      }
      else dispatch({
          type: 'LOGIN_FAIL',
        });
  } catch (err) {
    dispatch({
      type: 'LOGIN_FAIL',
    });
  }
};

export const createTest = async (dispatch, testname, subid, testtime, startTestTime, endTestTime, categoryName, categoryid,
   categoryType) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "tests",
    document: {
      testname,
      subid: String(subid),
      testtime: parseInt(testtime),
      createdBy: parseInt(localStorage.getItem("user.uid")),
      isActive: 1,
      startTestTime, 
      endTestTime,
      isVisible: true,
      categoryName,
      categoryid:String(categoryid),
      categoryType: parseInt(categoryType)
    },
  }
  try {
    await axios.post(`${baseURL}/createTest`, body, config);
    dispatch({
      type: "ACTION_SUCCESS",
    });
    getFilteredTests(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const getTests = async (dispatch, subid) => {
  if(subid!==undefined){
    subid = String(subid)
  }
  const tests = []
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "tests",
    Filter: {
      // createdBy: parseInt(localStorage.getItem("user.uid")),
      subid,
    },
  }
  try {
    const res = await axios.post(`${baseURL}/getTests`, body, config);
    res.data.map((test) => {
      return tests.push(test);
    });
    dispatch({
      type: "GET_TESTS",
      tests: tests,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateTest = async (
  dispatch,
  testname,
  testid,
  testtime,
  subid,
  subname, 
  startTestTime,
  endTestTime,
  categoryName,
  categoryid,
  categoryType
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "tests",
    Filter: {
      testid: parseInt(testid),
      // createdBy: parseInt(localStorage.getItem("user.uid")),
    },
    DataToBeUpdated: {
      testname,
      testid: parseInt(testid),
      testtime: parseInt(testtime),
      subid: String(subid),
      subname,
      isActive: 1,
      startTestTime,
      endTestTime,
      createdBy: parseInt(localStorage.getItem("user.uid")),
      categoryName,
       categoryid: String(categoryid),
      categoryType: parseInt(categoryType)
    },
  }
  try {
    await axios.post(`${baseURL}/updateTest`, body, config);
    dispatch({
      type: "ACTION_SUCCESS",
    });
    getFilteredTests(dispatch)
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const addQuestion = async (
  dispatch,
  testid,
  question,
  optionA,
  optionB,
  optionC,
  optionD,
  correctAns,
  marks,
  section,
  sectionid,
  img
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    document: {
      createdBy: parseInt(localStorage.getItem("user.uid")),
      isActive: 1,
      testid: parseInt(testid),
      question,
      type: 1,
      optionA,
      optionB,
      optionC,
      optionD,
      correctAns,
      marks : parseInt(marks),
      section,
      sectionid,
      img
    },
  }
  try {
    await axios.post(`${baseURL}/createQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "QUESTION_CREATED",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const addQuestion2 = async (dispatch, testid, question, marks, section) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    document: {
      testid: parseInt(testid),
      question,
      marks: parseInt(marks),
      section,
      createdBy: parseInt(localStorage.getItem("user.uid")),
      isActive: 1,
      type: 2,
    },
  };
  try {
    await axios.post(`${baseURL}/createQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "QUESTION_CREATED",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const addNote = async (dispatch, testid, title, content, img) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    document: {
      testid: parseInt(testid),
      title,
      content,
      img,
      createdBy: parseInt(localStorage.getItem("user.uid")),
      isActive: 1,
      type: 3,
      marks: 0
    },
  };
  try {
    await axios.post(`${baseURL}/createQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "QUESTION_CREATED",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const addImgQn = async (dispatch, testid, img, marks, correctAns, section, sectionid) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    document: {
      testid: parseInt(testid),
      img,
      marks: parseInt(marks),
      correctAns,
      section,
      sectionid,
      createdBy: parseInt(localStorage.getItem("user.uid")),
      isActive: 1,
      type: 4,
    },
  };
  try {
    await axios.post(`${baseURL}/createQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "QUESTION_CREATED",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const viewQuestions = async (dispatch, testid) => {
  const questions = [];
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    Filter: {
      testid: parseInt(testid),
    },
  }
  try {
    const res = await axios.post(`${baseURL}/viewQuestions`, body, config);
    res.data.map((question) => {
      return questions.push(question);
    });
    dispatch({
      type: "GET_QUESTIONS",
      questions: questions,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const updateQuestion = async (
  dispatch,
  qid,
  question,
  optionA,
  optionB,
  optionC,
  optionD,
  correctAns,
  marks,
  testid,
  oldmarks,
  section,
  sectionId,
  img
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    oldMarks: oldmarks,
    testid: parseInt(testid),
    collection: "questions",
    Filter: {
      qid,
    },
    DataToBeUpdated: {
      question,
      optionA,
      optionB,
      optionC,
      optionD,
      correctAns,
      marks: parseInt(marks),
      section,
      sectionId,
      img,
      createdBy: parseInt(localStorage.getItem("user.uid")),
    },
  };
  try {
    await axios.post(`${baseURL}/updateQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateQuestion2 = async (
  dispatch,
  qid,
  question,
  marks,
  oldmarks,
  testid,
  img,
  section,
  sectionId
  
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    oldMarks: oldmarks,
    testid: parseInt(testid),
    Filter: {
      qid,
    },
    DataToBeUpdated: {
      question,
      marks: parseInt(marks),
      img,
      section,
      sectionId,
      createdBy: parseInt(localStorage.getItem("user.uid")),
    },
  };

  try {
    await axios.post(`${baseURL}/updateQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const updateNotes = async (
  dispatch,
  qid,
  title,
  content,
  img,
  testid
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    oldMarks: 0,
    testid: parseInt(testid),
    Filter: {
      qid,
    },
    DataToBeUpdated: {
      title,
      content,
      img,
      marks: 0,
      createdBy: parseInt(localStorage.getItem("user.uid")),
    },
  };

  try {
    await axios.post(`${baseURL}/updateQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const updateImgQn = async (
  dispatch,
  oldMarks,
  qid,
  testid,
  img,
  marks,
  correctAns,
  section,
  sectionid
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "questions",
    oldMarks: oldMarks,
    testid: parseInt(testid),
    Filter: {
      qid,
    },
    DataToBeUpdated: {
      testid: parseInt(testid),
      img,
      marks: parseInt(marks),
      correctAns,
      section,
      sectionid,
      createdBy: parseInt(localStorage.getItem("user.uid"))
    },
  };

  try {
    await axios.post(`${baseURL}/updateQuestion`, body, config);
    viewQuestions(dispatch, testid);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const viewResults = async (dispatch) => {
  const results = []
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "submissions",
    Filter:{
      testid: localStorage.getItem("testid"),
    }
  }
  try {
    const res = await axios.post(`${baseURL}/viewResults`, body, config);
    res.data.map((result) => {
      return results.push(result);
    })
    dispatch({
      type: "GET_RESULTS",
      results: results,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const viewSubmission = async (dispatch, testid, submitID) => {
  const submission = []
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "submissions",
    Filter:{
      testid,
      submissionID : parseInt(submitID)
    }
  }
  try {
    const res = await axios.post(`${baseURL}/viewSubmission`, body, config);
    res.data.map((ques) => {
      return submission.push(ques);
    })
    localStorage.removeItem("testname")
    dispatch({
      type: "GET_SUBMISSION",
      submission: submission,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const updateUserName = async (dispatch, fname, lname) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: 'ExaminationSystem',
    collection: 'users',
    Filter:{
      uid: parseInt(localStorage.getItem("user.uid"))
    },
   DataToBeUpdated: {
      fname,
      lname,
  }}
  try {
    await axios.post(`${baseURL}/updateUser`, body, config)
    dispatch({
      type: "ACTION_SUCCESS",
    });
    readUser4Client(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });

  }
}

export const createSection = async (dispatch, section, testid) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "sections",
    document: {
        // createdBy: parseInt(localStorage.getItem("user.uid")),
        isActive: 1,
        section,
        testid: parseInt(testid)
    }
}
  try {
    await axios.post(`${baseURL}/createSection`, body, config);
    getSections(dispatch, testid);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSections = async (dispatch, testid) => {
  const sections = [];
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };
  const body = {
    database: "ExaminationSystem",
    collection: "sections",
    Filter: {
      // createdBy: parseInt(localStorage.getItem("user.uid")),
      testid: parseInt(testid)
    },
  };
  try {
    const res = await axios.post(`${baseURL}/getSections`, body, config);
    res.data.map((section) => {
      return sections.push(section);
    });
    dispatch({
      type: "GET_SECTIONS",
      sections: sections,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateSection = async (dispatch, testid, sectionid, section) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "sections",
    Filter: {
      testid: parseInt(testid),
      sectionid: parseInt(sectionid)
    },
    DataToBeUpdated: {
      testid: parseInt(testid),
      sectionid: parseInt(sectionid),
      section,
      // createdBy: parseInt(localStorage.getItem("user.uid")),
      isActive: 1,
    }
  }
  try {
    const res = await axios.post(`${baseURL}/updateSection`, body, config);
    getSections(dispatch, testid);
    res.data.status === 1
      ? dispatch({
          type: "ACTION_SUCCESS",
        })
      : dispatch({
          type: "ACTION_FAIL",
        });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const duplicateTest = async (dispatch, testid, createdBy, testname, testtime, subid, subname, startTestTime, endTestTime, categoryName, categoryid, total, categoryType ) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database:"ExaminationSystem",
    collection:"tests",
    testid: parseInt(testid),
    createdBy: parseInt(createdBy),
    document:{
      testname,
      testtime: parseInt(testtime),
      subid: String(subid),
      subname,
      startTestTime,
      endTestTime,
      categoryName,
      categoryid:String(categoryid),
      totalMarks: parseInt(total),
      categoryType: parseInt(categoryType),
      isActive: 1,
      isVisible: true,
      // createdBy: parseInt(localStorage.getItem("user.uid")),
    }
  }
  try {
    await axios.post(`${baseURL}/duplicateTest`, body, config);
    dispatch({
      type: "ACTION_SUCCESS",
    });
    getFilteredTests(dispatch)
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const getFilteredTests = async (dispatch) => {
  var value
  try{
     var retrievedObject = JSON.parse(localStorage.getItem('filterid'));
      value = retrievedObject.retrievedObject
  }
  catch {
    value = {}
  }

  const tests = []
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body =  {
    database: "ExaminationSystem",
    collection: "tests",
    Filter: retrievedObject,
  }
  try {
    const res = await axios.post(`${baseURL}/getTests`, body, config);
    res.data.map((test) => {
      return tests.push(test);
    });
    dispatch({
      type: "GET_TESTS",
      tests: tests,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};