import React from "react";
import clsx from "clsx";
import {
  List,
  ListItem,
  Drawer,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Loading } from "../Loading";
import UpdateLink from "./UpdateLink";
import Visibility from "../SubAdminDashboard/Visibility";
import { useAuthState, visibleLink } from "../../context";

const useStyles = makeStyles({
  list: {
    width: 600,
  },
  fullList: {
    width: "auto",
  },
  loader: {
    width: "auto",
  },
});

export default function ViewLink() {
  const [{ links, loading }] = useAuthState();
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List>
        {loading ? (
          <Loading />
        ) : links.filter((link) => link.isActive === 1).length === 0 ? (
          <h4 className="text-center text-secondary border border-info p-3 mt-5">
            No Links Available!
          </h4>
        ) : (
          links
            .filter((link) => link.isActive === 1)
            .map(({ link, linkid, linktitle, isVisible }) => (
            <div className="d-flex justify-content-center border">

              <UpdateLink
                key={linkid}
                ilink={link}
                ilinktitle={linktitle}
                linkid={linkid}
              />
              <div className="ml-1 mr-1 border">
                <Visibility id={linkid} isVisible={isVisible} updateFun={visibleLink}/>
              </div>
            </div>
            ))
        )}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <ListItem button onClick={toggleDrawer("right", true)}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary={"View Links"} />
        </ListItem>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
