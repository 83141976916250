import React from 'react';
import PropTypes from 'prop-types'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getFilteredTests, getTests, useAuthState } from '../../context';


function CategoryList(props) {
  const [{categories}, dispatch] = useAuthState()
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (e, value) => {
    console.log(value)

    var filterid
    if (value === undefined)
    filterid = {}
    else
    filterid = {categoryid: String(value)}
    localStorage.setItem('filterid', JSON.stringify(filterid));
    getFilteredTests(dispatch)
    onClose(value);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="select-ubject" open={open}>
      <DialogTitle id="select-subject" className="text-secondary text-center">Select a Category</DialogTitle>
      <List>
        <ListItem button onClick={() => handleListItemClick(undefined)}>
        <ListItemText primary="All"/>
        </ListItem>
        {categories.map(({categoryName, categoryid}) => (
          <ListItem button onClick={(e) => handleListItemClick(e, categoryid)} key={categoryid}>
            <ListItemText primary={categoryName} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}



export default function CategoryFilter({getTests}) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list"  onClick={handleClickOpen}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <CategoryList selectedValue={selectedValue} open={open} onClose={handleClose} getTests={getTests}/>
    </div>
  );
}


CategoryList.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};