import React from "react";
import "./home.css";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../images/ShivalikCollege.svg"
function Home() {
    useEffect(() => {
        localStorage.clear()
    }, [])
  return (
    <div className="home">
      <nav className="nav__bar">
        <div className="d-flex">
        <div className="nav__btn pt-3" >
            <img src={Logo} alt="shivalik" style={{height: '100%', width: "170%", marginTop:"-20px"}}/>
        </div>
        </div>
      </nav>

    <div className="containers text-center">
    <Typography variant="h2" className="h2">Shivalik College</Typography>
        <Typography variant="h2" className="h2" >Assessment Portal</Typography>
        <div className="mt-5">
            <NavLink className="nav__btn__link btn3" to="/login" role="button">Get Started</NavLink>
        </div>
    </div>
    </div>
  );
}

export default Home;
