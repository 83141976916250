import React from 'react';
import Switch from '@material-ui/core/Switch';
import { useAuthState } from '../../context';

export default function Visibility ({id, isVisible, updateFun}) {
    const [{}, dispatch] = useAuthState()
  const [state, setState] = React.useState(isVisible)

  const handleChange = (event) => {
    setState(event.target.checked);
    handleSubmit(event)
  };

  const handleSubmit = (e) => {
      e.preventDefault()
      updateFun(dispatch, id, !state)
  }
  return (
    <>
      <Switch
        checked={state}
        name="state"
        onChange={(e) => handleSubmit(e)}
        value={state}
      />
    </>
  );
}
