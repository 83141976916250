import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { loginUser, useAuthState } from "../context";
import { Loading } from "../components/Loading";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
import signn from "../images/loginsvg.svg";
import Logo from "../images/ShivalikCollege.svg"
function Login() {
  const [type, setType] = useState("password");
  const [width, setCurrentwidth] = useState(window.innerWidth)
  const { register, handleSubmit, errors } = useForm();
  const [{ user, loading, errorMessage }, dispatch] = useAuthState();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const level = localStorage.getItem("user.level");

  

  const handleResize = (e) => {
    setCurrentwidth(window.innerWidth)
   };  
   useEffect(()=>{window.addEventListener("resize", handleResize);},[width])
  const onSubmit = (data, e) => {
    console.log(data)
    e.preventDefault();
    loginUser(dispatch, data);
  };

  if (isAuthenticated && (level === "2" || level === "4")) {
    return <Redirect to="/stud-dashboard" />;
  }

  return (
    <>
      <div className="content vw-100 overflow-hidden px-3 ">
        <div className="container shadow  bg-white rounded">
          <div className="row">
          <div className={width >= 789? "col-md-6 order-md-2 mt-5 pt-4" : "col-md-12 order-md-2 d-none"}>
              <img src={signn} alt="loginimg" className="img-fluid" />
            </div>
            <div className={width >= 789?"col-md-6 contents mt-3":"col-md-12 contents "}>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="mt-2">
                  <img src={Logo} alt="loginimg" className="img-fluid" width="95%" height="75%"/>
                    <h3 className="mt-4">
                      Sign In to <strong>Assessment Portal </strong>
                    </h3>
                  </div>
                  <form
                    className="mt-5"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-group first field--not-empty">
                      <label htmlFor="inputForEmail">Email</label>
                      <input
                        required
                        id="inputForEmail"
                        name="username"
                        autoComplete="off"
                        type="text"
                        className="form-control input"
                        aria-describedby="Enter email address"
                        
                        ref={register({
                          required: {
                            value: true,
                            message: "Please enter Your Email",
                          },
                        })}
                      />

                      {errors.username && (
                        <Alert
                          severity="warning"
                          className="errorMessage text-capitalize ml-5"
                        >
                          {errors.email.message}
                        </Alert>
                      )}
                    </div>

                    <div className="form-group last mb-4 pt-3 field--not-empty">
                      <label htmlFor="password">Password</label>
                      <input
                        type={type}
                        id="password"
                        name="password"
                        autoComplete="off"
                        className="form-control input"
                        ref={register({
                          required: {
                            value: true,
                            message: "Please  Enter Your password",
                          },
                        })}
                      />
                      <div className="btn-show-pass"> 
                      {type === "password" ? (
                        <span
                          className="fa fa-eye "
                          type="button"
                          onClick={() => {
                            setType("text");
                          }}
                        ></span>
                      ) : (
                        <span
                          className="fa fa-eye-slash "
                          type="button"
                          onClick={() => {
                            setType("password");
                          }}
                        ></span>
                      )}
                      </div>
                      <br />

                      {errors.password && (
                        <Alert
                          severity="warning"
                          className="errorMessage text-capitalize ml-5"
                        >
                          {errors.password.message}
                        </Alert>
                      )}
                    </div>
                   
                    <button type="submit" className="btn btn-primary btn-block" >
            {loading? <Loading />: "Login"}
            </button>
                    <span className="d-block text-center my-4 pt-1 text-muted">
                      <Link to="/register" style={{ textDecoration: "none" }}>
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
