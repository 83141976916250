import axios from "axios";
import { getCategories, getSubjects, getLinks, getFilteredTests} from "..";

const baseURL = "https://tapapi.shivalikcollege.edu.in";

export const visibleTest = async (
    dispatch,
    testid,
    isVisible
  ) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      database: "ExaminationSystem",
      collection: "tests",
      Filter: {
        testid: parseInt(testid),
      },
      DataToBeUpdated: {
        // createdBy: parseInt(localStorage.getItem("user.uid")),
        testid: parseInt(testid),
        isVisible
      },
    }

    try {
      await axios.post(`${baseURL}/updateTest`, body, config);
      dispatch({
        type: "ACTION_SUCCESS",
      });
      getFilteredTests(dispatch)
    } catch (err) {
      dispatch({
        type: "ACTION_FAIL",
      });
    }
  };
  
  export const visibleCategory = async (dispatch, categoryid, isVisible) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      database: "ExaminationSystem",
      collection: "category",
      Filter: {
        categoryid,
      },
      DataToBeUpdated: {
        // createdBy: localStorage.getItem("user.uid"),
        categoryid,
        isVisible,
      },
    };
    try {
      const res = await axios.post(`${baseURL}/updateCategory`, body, config);
      getCategories(dispatch);
      res.data.status === 1
        ? dispatch({
            type: "ACTION_SUCCESS",
          })
        : dispatch({
            type: "ACTION_FAIL",
          });
    } catch (err) {
      dispatch({
        type: "ACTION_FAIL",
      });
    }
  }

  export const visibleSubject = async (dispatch, subid, isVisible) => {

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      database: "ExaminationSystem",
      collection: "subjects",
      Filter: {
        subid,
      },
      DataToBeUpdated: {
        // createdBy: localStorage.getItem("user.uid"),
        subid,
        isVisible
      },
    };
    try {
      const res = await axios.post(`${baseURL}/updateSubject`, body, config);
      getSubjects(dispatch, "load");
      res.data.status === "1"
        ? dispatch({
            type: "ACTION_SUCCESS",
          })
        : dispatch({
            type: "ACTION_FAIL",
          });
      getSubjects(dispatch);
    } catch (err) {
      dispatch({
        type: "ACTION_FAIL",
      });
    }
  };

  export const visibleLink = async (dispatch, linkid, isVisible) => {

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      database: "ExaminationSystem",
      collection: "links",
      Filter: {
        linkid,
      },
      DataToBeUpdated: {
        // createdBy: localStorage.getItem("user.uid"),
        linkid,
        isActive: 1,
        isVisible
      },
    };
    try {
      const res = await axios.post(`${baseURL}/updateLink`, body, config);
      getLinks(dispatch, "load");
      res.data.status === 1
        ? dispatch({
            type: "ACTION_SUCCESS",
          })
        : dispatch({
            type: "ACTION_FAIL",
          });
    } catch (err) {
      dispatch({
        type: "ACTION_FAIL",
      });
    }
  };