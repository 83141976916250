import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { TextField } from "@material-ui/core";
import { useAuthState } from "../../context";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Settime from "./Settime";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    minwidth: 100,
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export default function UpdateTest({
  info,
  createdBy,
  initialTestTime,
  initialSubid,
  initialSubName,
  initialTestName,
  testid,
  fun,
  total,
  initialStartTime,
  initialEndTime,
  initialCategoryid,
  initialCategoryName,
  initialCategoryType
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [subid, setSubid] = useState(parseInt(initialSubid));
  const [testname, setTestname] = useState(initialTestName);
  const [subname, setSubname] = useState(initialSubName);
  const [categoryName, setCategoryName] = useState(initialCategoryName);
  const [categoryid,setCategoryId] = useState(initialCategoryid);
  const [categoryType,setCategoryType] = useState(initialCategoryType);
  const [{ subjects, categories }, dispatch] = useAuthState();
  const [testtime, setTesttime] = useState(initialTestTime);
  const [valueError, setValueError] = useState();
  const [startTestTime, setStartTestTime] = useState(initialStartTime);
  const [endTestTime, setEndTestTime] = useState(initialEndTime);
  const handleInputChange = (e) => {
    setSubid(String(e.target.value) || "");
  };
  
  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value)
    getCategoryType(e.target.value)
  }

  const getCategoryType = async (value) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      database: "ExaminationSystem",
      collection: "category",
      Filter: {
        categoryid: value
      },
    };
  
    try {
      const res = await axios.post("https://tapapi.shivalikcollege.edu.in//viewCategory", body, config);
      setCategoryType(res.data[0].categoryType)
    } catch (err) {
      console.log(err)
    }
  }


  const handleTestnameChange = (e) => {
    setTestname(e.target.value);
  };

  const handleTesttimeChange = (e) => setTesttime(e.target.value);
  
  const handleStartTimeChange = (e) => {
    const today = new Date(e.target.value);
    setStartTestTime(today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
  };

  const handleEndTimeChange = (e) => {
    const today = new Date(e.target.value);
    setEndTestTime(today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (testname === "" || subname === "" || testtime <= 0 || categoryName === "" || categoryid==="" || categoryType ==="")
      setValueError("Enter all the values!");
    else {
      setValueError("");
      info === 0 ?
      fun(
        dispatch,
        testname,
        testid,
        testtime,
        subid,
        subname,
        startTestTime,
        endTestTime,
        categoryName,
        categoryid,
        categoryType,
      )
      :
      fun(
        dispatch,
        testid,
        createdBy,
        testname,
        testtime,
        subid,
        subname,
        startTestTime,
        endTestTime,
        categoryName,
        categoryid,
        total,
        categoryType
      )
    }
    if (valueError === "") handleClose();
  };


  const handleSubName = (e, subname) => {
    setSubname(subname);
  };
  const handleCatName = (e, categoryName)=>{
    setCategoryName(categoryName)
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {info === 0 ? 
      <EditOutlinedIcon
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
      />
      :
      <FileCopyIcon         
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }} 
      />
      }

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <form
          className={classes.container}
          id="test-form"
          noValidate
          autoComplete="off"
        >
          <DialogTitle>Create a new test</DialogTitle>

          <DialogContent>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="testname"
                label="Name of the test"
                name="testname"
                type="text"
                value={testname}
                autoComplete="testname"
                autoFocus
                onChange={(e) => handleTestnameChange(e)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-category-label">Category</InputLabel>
              <Select
                id="select-category"
                defaultValue=""
                value={categoryid}
                onChange={(e) => handleCategoryChange(e)}
                input={<Input id="select-category-label" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categories.map(({ categoryName, categoryid, isActive }) => (
                  isActive === 1 ? 
                  <MenuItem
                   key={categoryid} 
                   value={categoryid}
                   onClick={(e) => handleCatName(e, categoryName)}
                   >
                    {categoryName}
                  </MenuItem> :
                  null
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-subject-label">Subject</InputLabel>
              <Select
                id="select-subject"
                defaultValue=""
                value={subid}
                onChange={(e) => handleInputChange(e)}
                input={<Input id="select-subject-label" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {subjects.filter((subject) => subject.categoryid === String(categoryid)).map(({ subname, subid, isActive }) => (
                  isActive === 1?
                  <MenuItem
                    key={subid}
                    value={subid}
                    onClick={(e) => handleSubName(e, subname)}
                  >
                    {subname}
                  </MenuItem>: null
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="testtime"
                label="Time limit"
                helperText="(in minutes)"
                name="testtime"
                type="number"
                value={testtime}
                autoComplete="testtime"
                autoFocus
                onChange={(e) => handleTesttimeChange(e)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              {startTestTime}
              <Settime
               startTestTime={startTestTime}
                onChange={handleStartTimeChange}
                label="Start Date & Time"
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              {endTestTime}
              <Settime
                onChange={handleEndTimeChange}
                label="End  Date & Time"
               
                endTestTime={endTestTime}
              />
            </FormControl>
          </DialogContent>
        </form>
        {valueError ? (
          <p className="text-small text-danger ml-4">{valueError}</p>
        ) : (
          <p></p>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary" form="test-form">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
