import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { AccordionActions, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { useAuthState, updateCategory, deleteCategory } from '../../context';
import DeleteItem from "../SubAdminDashboard/DeleteItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "300px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  delete: {
    color: 'red',
    fontSize: "2rem",
    marginRight: "1rem",
    cursor: 'pointer',
    float: 'right',
  }
}));

export default function UpdateCategory({categoryid, icategoryName, icategory}) {
  const [categoryName, setCategoryName] = useState(icategoryName);
  const [categoryType, setCategoryType] = useState(icategory);
  const [{}, dispatch] = useAuthState()
  const [valueError, setValueError] = React.useState('')
  console.log(icategoryName, icategory)
  const handleSubmit = (e) => {
    e.preventDefault();
    if (categoryName === '')
      setValueError("Enter the Category!")
    else
      {setValueError("")
      updateCategory(dispatch, categoryid, categoryName, categoryType);
      setForm(categoryName)}
  }

  const setForm = (categoryName) => {
    setCategoryName(categoryName)
  }
  const classes = useStyles();
  return (
    <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
      <Accordion square >
        <AccordionSummary
          expandIcon={<EditOutlinedIcon className="text-success"/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{categoryName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div>
        <TextField id={categoryName} value={categoryName} onChange={(e) => setCategoryName(e.target.value)}/>
        <FormControl component="fieldset" className="mt-4">
              <RadioGroup aria-label="CategoryType" name="CategoryType" value={categoryType} onChange={(e) => setCategoryType(e.target.value)}>
                <FormControlLabel value="2" control={<Radio />} label="For College Students" />
                <FormControlLabel value="4" control={<Radio />} label="For Others" />
              </RadioGroup>
        </FormControl>
         </div>   
        </AccordionDetails>
        <AccordionActions>
          {valueError ? <p className="text-small text-danger ml-4">{valueError}</p> : <p></p>}
          <Button size="small" color="primary" type="submit">
            Save
          </Button>
        </AccordionActions>
        <Divider />
      </Accordion>
    </form>
  );
}
