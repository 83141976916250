import React, { useState } from "react";
import { updatePass, useAuthState } from "../../context";
import { Loading } from "../Loading";
import Nav from "./Nav";

const ChangePass = () => {
  const [{ user, load }, dispatch] = useAuthState();
  const [prevPass, setPrevPass] = useState("")
  const [pass, setPass] = useState("")
  const [incorrect, setIncorrect] = useState("")
  const [error, setError] = useState("")

  const [confirmPass, setConfirmPass] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pass === "" || confirmPass === "" || prevPass === "") {
      setError("Fill all the fields!")
    } else {
      setError('')
      setIncorrect("Incorrect Password!")

        if(prevPass === user[0].password) {
          if(pass === confirmPass){
            updatePass(dispatch, pass)
            resetForm()
          }
          else 
            setError("Passwords didn't match")
        } else {
          setIncorrect("Incorrect Password!")
        }
    }
  } 

  const resetForm = () => {
    setIncorrect("")
    setError("")
    setConfirmPass("")
    setPass("")
    setPrevPass("")
  }
  return (
    <>
      <Nav />
      <div className="container">
      <div class="card-header">
          <h3 class="mb-0">Change your password</h3>
        </div>
        <form onSubmit={handleSubmit} className="card p-5 shadow-lg">
          <div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">New Password*</label>
              <input
                type="password"
                className="form-control"
                value={pass}
              onChange={(e)=> setPass(e.target.value)}
                id="exampleInputPassword1"
                placeholder="Password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword2">Confirm New Password*</label>
              <input
                type="password"
                className="form-control"
                value={confirmPass}
                onChange={(e)=> setConfirmPass(e.target.value)}
                id="exampleInputPassword2"
                placeholder="Confirm Password"
              />
            </div>
            <div className="form-group">
            <label htmlFor="currentPassword">Verify*</label>
            <input
              type="password"
              className="form-control"
              value={prevPass}
              onChange={(e)=> setPrevPass(e.target.value)}
              id="currentPassword"
              placeholder="Current Password"
            />
            {incorrect !== "" ? <p className="mt-2 text-danger">{incorrect}</p>: null}
          </div>
          {error !== "" ? <p className="mt-2 text-danger">{error}</p>: null}
            <button type="submit" className="btn btn-primary btn-block" onClick={handleSubmit}>
            {load? <Loading />: "Change"}
            </button>
          </div>

        </form>
      </div>
    </>
  );
};

export default ChangePass;
