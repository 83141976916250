import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import DashboardData from '../components/SubAdminDashboard/DashboardData'
import { getSubjects, getCategories, useAuthState, readUser4Client, getFilteredTests } from "../context";

function SubAdminDashboard() {
  const [{}, dispatch] = useAuthState()
  const isAuthenticated = localStorage.getItem("isAuthenticated")
  const level = localStorage.getItem("user.level")

  useEffect(() => {
    getSubjects(dispatch)
    getFilteredTests(dispatch)
    getCategories(dispatch)
    readUser4Client(dispatch)
  }, [])

  if (!isAuthenticated || !level === "1") return <Redirect to="/sub-admin-login" />

  return (
    <>
      <div>
        <DashboardData/>
      </div>
    </>
  );
}

export default SubAdminDashboard;
