import React from "react";
import clsx from "clsx";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import UpdateCategory from './UpdateCategory'
import { Loading } from "../Loading";
import Visibility from "../SubAdminDashboard/Visibility";
import DeleteItem from "../SubAdminDashboard/DeleteItem";
import { deleteCategory, useAuthState, visibleCategory } from "../../context";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  loader: {
    width: "auto",
  },
});

export default function ViewCategory() {
  const [{ categories, loading }, dispatch] = useAuthState();
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List style={{overflowY:"scroll", height:"auto", width:"570px"}}>
        { loading ? (<Loading />) : 
          categories.filter((category) => category.isActive === 1).length === 0 ? (
          <h6 className="text-center text-secondary border border-info p-3 mt-5">
            No Categories Available!
          </h6>
        ) :
        (categories.filter((ctgry) => ctgry.isActive === 1).map(({ categoryName, categoryid, isVisible, categoryType }, index) =>
            <div className="d-flex justify-content-center border" key={categoryid}>
              <UpdateCategory key={index} icategoryName={categoryName} categoryid={categoryid} icategory={categoryType}/> 
              <span className="ml-4 mt-2 d-flex justify-content-center">
                <DeleteItem deleteFun={() => deleteCategory(dispatch, categoryid)} item={categoryName}/>
                <Visibility id={categoryid} isVisible={isVisible} updateFun={visibleCategory}/>
              </span>
            </div>
        ))}
      </List>
    </div>
  )

  return (
    <div>
      <React.Fragment key={"right"}>
        <ListItem button onClick={toggleDrawer("right", true)}>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary={"View Categories"} />
        </ListItem>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
        <DialogTitle>Categories</DialogTitle>
        <DialogContent><DialogContentText>{list("right")}</DialogContentText> </DialogContent>
         
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)}>Close</Button>
      </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}


