import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import DashboardData from '../components/AdminDashboard/DashboardData'

function AdminDashboard() {
  const isAuthenticated = localStorage.getItem("isAuthenticated")
  const level = localStorage.getItem("user.level")

  useEffect(() => {

  })
  if(!isAuthenticated || level !== "3") return <Redirect to="admin" />

  return (
    <>
      <div>
        <DashboardData/>
      </div>
    </>
  );
}

export default AdminDashboard;
