import React, {useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function Settime(props) {
  const classes = useStyles();
  const today = new Date()
  const current = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() 
 let defaultValue = props.startTestTime? props.startTestTime:props.endTestTime
 let arr  = []
 if(defaultValue){
  
  const str = defaultValue.replaceAll("/", "-").replaceAll(",","T").replaceAll(" ", "").replaceAll("PM", "").replaceAll("AM", "")
  const chars = str.split('');
  arr[0] = (chars[0]+chars[1] !== '1-' )? ((chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] === chars[3]+"-")?(chars[5]+chars[6]+chars[7]+chars[8]):(chars[6]+chars[7]+chars[8]+chars[9])):(chars[6]+chars[7]+chars[8]+chars[9])): ((chars[2]+chars[3] === chars[2]+'-' )?chars[4]+chars[5]+chars[6]+chars[7]:chars[5]+chars[6]+chars[7]+chars[8] )//year
  arr[1] = (chars[0]+chars[1] !== '1-' )? chars[2]: chars[1]//-
  arr[2] = (chars[0]+chars[1] !== '1-' )?  chars[0] + chars[1]: '0'+chars[0]//month
  arr[3] = (chars[0]+chars[1] !== '1-' )? ((chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] !== chars[3]+"-")?chars[5]:chars[4]):chars[5]): ((chars[2]+chars[3] === chars[2]+'-' )?chars[3]:chars[4])// -
  arr[4] = (chars[0]+chars[1] !== '1-' )? ((chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] !== chars[3]+"-")?chars[3]+chars[4]:'0'+chars[3]):chars[3]+chars[4]):(chars[2]+chars[3] === chars[2]+'-' )?'0'+chars[2]:chars[2]+chars[3]// Day
  arr[5] = (chars[0]+chars[1] !== '1-' )? (chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] !== chars[3]+"-")?chars[10]:chars[9]):chars[10]:(chars[2]+chars[3] === chars[2]+'-' )?chars[8]:chars[9]//T
  arr[6] = (chars[0]+chars[1] !== '1-' )? ((chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] !== chars[3]+"-")?((chars[11]+chars[12]=== chars[11]+":")?"0"+chars[11]:(chars[11]+chars[12])):((chars[10]+chars[11]===chars[10]+":")?'0'+chars[10]:chars[10]+chars[11])):chars[10]+chars[11]):(chars[2]+chars[3] === chars[2]+'-' )?'0'+chars[9]:(chars[10]+chars[11]=== chars[10]+":"? '0'+chars[10]:chars[10]+chars[11])//hours
  arr[7] = (chars[0]+chars[1] !== '1-' )? ((chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] === chars[3]+"-")?((chars[10]+chars[11]===chars[10]+":")?chars[11]:chars[12]):((chars[11]+chars[12]===chars[11]+":")?chars[12]:chars[13])):((chars[11]+chars[12]===chars[11]+":")?chars[12]:chars[13])):(chars[2]+chars[3] === chars[2]+'-' )?chars[10]:(chars[10]+chars[11]=== chars[10]+":"? chars[11]: chars[12])//:
  arr[8] = (chars[0]+chars[1] !== '1-' )? ((chars[2]+chars[3] === '-' + chars[3] )?((chars[3]+chars[4] !== chars[3]+"-")?((chars[13]+chars[14]===":"+chars[14])?chars[14]+chars[15]:chars[13]+chars[14]):((chars[10]+chars[11]=== chars[10]+":")?chars[12]+chars[13]:chars[13]+chars[14])):chars[13]+chars[14]):((chars[2]+chars[3] === chars[2]+'-' )?chars[11]+chars[12]:(chars[10]+chars[11]=== chars[10]+":"?((chars[9]+chars[11] !== "T:")?'0'+chars[13]:chars[12]+chars[13]):chars[13]+chars[14])) //min
 }
 const defaultValues = arr[0]+arr[1]+arr[2]+arr[3]+arr[4]+arr[5]+arr[6]+arr[7]+arr[8]

  return(
    <form className={classes.container} noValidate>
   
      <TextField
        id="datetime-local"
        label={props.label}
        type="datetime-local"
        format="dd/MM/yyyy"
        defaultValue={defaultValues}
        //defaultValue="2021-01-21T23:30"
        className={classes.textField}
        onChange = {props.onChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
