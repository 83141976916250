import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { getSubjects4Client, useAuthState } from "../../context";
import Paper from "@material-ui/core/Paper";
import Nav from "./Nav";
import LinkCarousel from "./LinkCarousel";
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  bodyCard: {
    display: "flex",
    marginTop: 0,
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0),
      width: theme.spacing(46),
      height: theme.spacing(10),
    },
  },
  main: {
    flexGrow: 1,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    fontWeight: 800,
    width: "100%",
    height:"130px",
    border: "none",
    margin: 'normal',
    borderBottom: "1px solid #fff",
    fontSize: 18,
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    boxShadow: "7px 8px 10px #2222",
    "&:hover": {
      boxShadow: "7px 8px 10px #3f51b5",color:"#3f51b5",
    },
    "&:active": {
      backgroundColor: "#b8daff",
    },
  },

  btn:{
    marginTop:"12px",
    width:"100%"
  }
}));

function SelectCategory() {
  const classes = useStyles();
  const [{ loading, links, categories }, dispatch] = useAuthState();

  const clickHandler = (categoryid, category) => {
    localStorage.setItem("categoryid", categoryid)
    getSubjects4Client(dispatch, categoryid)
    localStorage.setItem("category", category)
  }
  return (
    <>
    <Nav />
      {loading ? (<div className="container">
        <div className="row"><div  className="col-sm-12 mb-4" ><Skeleton variant="rect" height={80}/></div><br/><div className="col-sm-4" ><Skeleton variant="rect" height={70}/></div><br/><div className="col-sm-4" ><Skeleton variant="rect" height={70}/></div><br/><div className="col-sm-4" ><Skeleton variant="rect" height={70}/></div><br/></div></div>
      ) : (
        <div className={classes.main}>
          <div className="container">
        
            <h3 className="text-light">Select a Category</h3>
            <div className={classes.bodyCard}>
              {categories.filter((category) => category.isActive === 1 && category.isVisible).map(({ categoryName, categoryid }) => (
                <Grid container spacing={4} key={categoryid} className="mb-5 mt-3">
                  <Grid item xs={12} >
                    <Link to={{ pathname: "/subject-test-view" }} >
                      <Paper
                        label={categoryName}
                        className={classes.paper}
                        onClick={() => clickHandler(categoryid, categoryName)}
                        component="button">
                        {categoryName}
                        <br/>
                         <Button className={classes.btn} color="primary" variant="contained" size="small">View Subjects</Button>
                        </Paper>
                    </Link>
                    
                  </Grid>
                </Grid>
              ))}
            </div>
            {
              links.filter((link) => link.isActive === 1 && link.isVisible).length !== 0 ? 
              <div className="mt-5">
                <h4 className="mt-5">Links</h4>
                <LinkCarousel />
              </div>
              : null
            }
          </div>
        </div>
      )}
    </>
  );
}

export default SelectCategory;
