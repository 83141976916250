import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Button, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import { updateImgQn, updateQuestion2, updateNotes, useAuthState } from '../../context';
import EditIcon from '@material-ui/icons/Edit';
import { Editor } from "@tinymce/tinymce-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function UpdateQn({iquestion, qid, ioptionA, ioptionB, ioptionC, ioptionD, icorrectAns, imarks, isection, isectionid, setLoad, updateQuestion, type, ititle, icontent, iimg}) {
  const testid = localStorage.getItem('testid')
  const [open, setOpen] = React.useState(false);
  const [correctAns, setCorrectAns] = useState(icorrectAns)
  const [section, setSection] = useState(isection)
  const [sectionId, setSectionId] = useState(isectionid)
  const [valueError, setValueError] = React.useState('')
  const [{sections}, dispatch] = useAuthState()
  const [img, setImg] = useState({ imgUpload: iimg });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSectionChange = (event) => {
    setSection(event.target.value);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const [qnData, setQnData1] = useState({
    marks: imarks,
    optionA: ioptionA,
    optionB: ioptionB,
    optionC: ioptionC,
    optionD: ioptionD
  });
  
  const {
    marks,
    optionA,
    optionB,
    optionC,
    optionD,
  } = qnData;


  const [title, setTitle] = useState(ititle);
  const [content, setContent] = useState(icontent);
  const [question, setQuestion] = useState(iquestion);




  const handleInputChange1 = (e) => setQnData1({ ...qnData, [e.target.name]: e.target.value });

  const handleChange = (event) => {
    setCorrectAns(event.target.value);
  }

  const handleImgChange = (event) => {
    var file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg({
        imgUpload: reader.result,
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (type === 1){
      if ( (question === "" && img === "") || optionA === '' || optionB === '' || optionC === '' || optionD === '' || marks === '' || correctAns === '')
        setValueError("Enter all the values!")
      else {
        setValueError("")
        updateQuestion(dispatch, qid, question, optionA, optionB, optionC, optionD, correctAns, marks, testid, imarks, section, sectionId, img.imgUpload)
        handleClose()
        setLoad(false)
      }
      }
    else if (type === 2){ 
      if ( (question === "" && img === "") || marks === '')
        setValueError("Enter all the values!")
      else {
        setValueError("")
        updateQuestion2(dispatch, qid, question, marks, imarks, testid, img.imgUpload, section, sectionId, )
        handleClose()
        setLoad(false)
      }
    }
    else if (type === 4){ 
      if (img === "" || marks === '' || correctAns === '')
        setValueError("Enter all the values!")
      else {
        setValueError("")
        updateImgQn(dispatch, imarks, qid, testid, img.imgUpload, marks, correctAns, section, sectionId, )
        handleClose()
        setLoad(false)
      }
    }
    else{
      if ( title === '' && (content === '' || img.imgUpload === ''))
        setValueError("Enter all the values!")
      else {
        setValueError("")
        updateNotes(dispatch, qid, title, content, img.imgUpload, testid )
        handleClose()
        setLoad(false)
      }
    }
    
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        startIcon={<EditIcon />}
        >
            Update
        </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update Question"}</DialogTitle>
        <DialogContent>
          <form  autoComplete="off" id="addQuestion" onSubmit={handleFormSubmit}>
          {type === 1 || type === 2 ?
            <Editor
                  initialValue={question}
                  apiKey="kck6txzugrflg6zurhyj1u89qzelzx05spfjcpueey21fa95"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={(e) => setQuestion(e)}
                /> : null }
              {type === 1 || type === 4 ? 
              <>
              <p className="text-center text-secondary">OR</p>
                {img && img.imgUpload !== null ? (
                  <img
                    src={img.imgUpload}
                    class="img-fluid"
                    alt="Responsive image"
                  />
                ) : null}
                <input
                  type="file"
                  onChange={(e) => handleImgChange(e)}
                  className="mt-4"
                />
              </> : null 
              }
            {type !== 3 ?
              <div className="ml-5 mr-5">
                <TextField
                  required
                  id="marks"
                  label="Marks"
                  name="marks"
                  fullWidth
                  type="number"
                  variant="outlined"
                  className="mt-3 "
                  value={marks}
                  onChange={(e) => handleInputChange1(e)}
                />
                <FormControl className="container mt-2 ">
                  <InputLabel>Select a section</InputLabel>
                  <Select
                    id="select-section"
                    value={section}
                    onChange={(e) => handleSectionChange(e)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sections.map(({sectionid, section, isActive}) =>
                      isActive === 1 ?
                      <MenuItem key={sectionid} value={section} onClick={ ()=> setSectionId(sectionid)}>{section}</MenuItem>
                      : 
                      null
                    )}
                  </Select>
                </FormControl>
            </div> : null
            }

            {type === 1 ? 
              <div>
                <TextField
                  id="answer_optionA"
                  label="optionA"
                  name="optionA"
                  fullWidth
                  type="text"
                  className="mt-2 mb-2"
                  value={optionA}
                  onChange={(e) => handleInputChange1(e)}
                />
                <TextField
                  id="answer_optionB"
                  label="optionB"
                  name="optionB"
                  fullWidth
                  type="text"
                  className="mt-2 mb-2"
                  value={optionB}
                  onChange={(e) => handleInputChange1(e)}
                />
                <TextField
                  id="answer_optionC"
                  label="optionC"
                  name="optionC"
                  fullWidth
                  type="text"
                  className="mt-2 mb-2"
                  value={optionC}
                  onChange={(e) => handleInputChange1(e)}
                />
                <TextField
                  id="answer_optionD"
                  label="optionD"
                  name="optionD"
                  fullWidth
                  type="text"
                  className="mt-2 mb-2"
                  value={optionD}
                  onChange={(e) => handleInputChange1(e)}
                /> 
                </div> 
                : null  }
              {type ===  1 || type === 4 ?
              <FormControl className="container mt-4 mb-4">
                <InputLabel>Correct Answer</InputLabel>
                <Select
                  id="select-option"
                  value={correctAns}
                  onChange={handleChange}
                >
                  <MenuItem value="a">a</MenuItem>
                  <MenuItem value="b">b</MenuItem>
                  <MenuItem value="c">c</MenuItem>
                  <MenuItem value="d">d</MenuItem>
                </Select>
              </FormControl>
              : null               
              }
            {type === 3 ? 
            <div>
            <Typography variant="h6"gutterBottom>Title/Question:</Typography>
              <TextareaAutosize
                required
                id="title"
                label="Title"
                name="title"
                type="text"
                className="mt-2 mb-2 h-2"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                aria-label="minimum height"
                style={{ width: "100%", minHeight: '20%', border: '3px solid #cccccc', padding: '7px'}}
                placeholder="   Type Title
                
                "
              />
              <Typography variant="h6"gutterBottom>Description:</Typography>
              <Editor
                  initialValue={content}
                  apiKey="kck6txzugrflg6zurhyj1u89qzelzx05spfjcpueey21fa95"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={(e) => setContent(e)}
                />
              <Typography className="p-5 text-center font-bold" variant="h5"gutterBottom>OR</Typography>
                {img && img.imgUpload !== null ? (
                  <>
                  <Typography variant="h6"gutterBottom>Solution:</Typography>

                  <img
                    src={img.imgUpload}
                    class="img-fluid"
                    alt="Responsive image"
                  />
                  </>
                ) : null}
                <input
                  type="file"
                  onChange={(e) => handleImgChange(e)}
                  className="mt-4"
                />
            </div> : null
            }            
          </form>
        </DialogContent>
        {valueError ? <p className="text-small text-danger ml-4">{valueError}</p>: <p></p>}
        <DialogActions>
          <Button onClick={handleFormSubmit} color="primary" form="addQuestion">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
