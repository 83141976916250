import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Editor } from "@tinymce/tinymce-react";

import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  addImgQn,
  addNote,
  addQuestion,
  addQuestion2,
  useAuthState,
} from "../../context";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: 330,
  },
});

export default function AddQns(props) {
  const testid = localStorage.getItem("testid");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const [correctAns, setCorrectAns] = useState("");
  const [valueError, setValueError] = React.useState("");
  const [sectionid, setSectionId] = useState("");
  const [section, setSection] = useState("");
  const [question, setQuestion] = useState("");

  const [img, setImg] = useState({ imgUpload: "" });

  const [{ loading, sections }, dispatch] = useAuthState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  const [qnData, setQnData] = useState({
    question: "",
    marks: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });
  const [qnData2, setQnData2] = useState({
    question2: "",
    marks2: "",
  });
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const [qnData4, setQnData4] = useState({
    marks4: "",
  });

  const { marks, optionA, optionB, optionC, optionD } = qnData;

  const { marks2 } = qnData2;

  const { marks4 } = qnData4;

  const handleInputChange = (e) => {
    setQnData({ ...qnData, [e.target.name]: e.target.value });
  };
  const handleInputChange2 = (e) =>
    setQnData2({ ...qnData2, [e.target.name]: e.target.value });

  const handleCorrectAns = (event) => {
    setCorrectAns(event.target.value);
  };
  const handleSectionChange = (event) => {
    setSection(event.target.value);
  };

  const handleImgChange = (event) => {
    var file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg({
        imgUpload: reader.result,
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      (question === "" && img === "") ||
      optionA === "" ||
      optionB === "" ||
      optionC === "" ||
      optionD === "" ||
      marks === ""
    )
      setValueError("Enter valid values!");
    else {
      setValueError("");
      addQuestion(
        dispatch,
        testid,
        question,
        optionA,
        optionB,
        optionC,
        optionD,
        correctAns,
        marks,
        section,
        sectionid,
        img.imgUpload
      );
      props.setLoad(false);
      formReset();
      handleClose();
    }
  };
  const handleFormSubmit2 = (e) => {
    e.preventDefault();
    if (question === "" || marks2 <= 0) setValueError("Enter valid values!");
    else {
      setValueError("");
      addQuestion2(dispatch, testid, question, marks2, section, sectionid);
      props.setLoad(false);
      formReset2();
      handleClose2();
    }
  };

  const handleFormSubmit3 = (e) => {
    e.preventDefault();
    if (title === "" || (content === "" && img.imgUpload === "")) setValueError("Enter valid values!");
    else {
      setValueError("");
      addNote(dispatch, testid, title, content, img.imgUpload);
      props.setLoad(false);
      formReset3();
      handleClose3();
    }
  };
  const handleFormSubmit4 = (e) => {
    e.preventDefault();
    if (img.imgUpload === "" || marks === "")
      setValueError("Enter valid values!");
    else {
      setValueError("");
      addImgQn(
        dispatch,
        testid,
        img.imgUpload,
        marks,
        correctAns,
        section,
        sectionid
      );
      props.setLoad(false);
      formReset4();
      handleClose4();
    }
  };
  const formReset = () => {
    setQnData("");
    setSection("");
    setSectionId("");
    setCorrectAns("");
    setImg({ imgUpload: "" });
  };

  const formReset2 = () => {
    setQnData2("");
    setSection("");
    setSectionId("");
  };

  const formReset3 = () => {
    setTitle("");
    setContent("");
    setImg({ imgUpload: "" });
  };

  const formReset4 = () => {
    setQnData4("");
    setSection("");
    setSectionId("");
    setCorrectAns("");
    setImg({ imgUpload: "" });
  };

  const classes = useStyles();
  return (
    <>
      {loading ? (
        null
      ) : (
        <div className="container mt-4 mb-4" style={{ marginTop: "120px" }}>
          <Paper className={classes.root}>
            <MenuList>
              <MenuItem>
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  MCQ Question
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={handleClickOpen4}
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  Image Qno
                </Button>
              </MenuItem>
              {/* <MenuItem>
                <Button
                  onClick={handleClickOpen2}
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  Descriptive Question
                </Button>
              </MenuItem> */}
              <MenuItem>
                <Button
                  onClick={handleClickOpen3}
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  Notes
                </Button>
              </MenuItem>
            </MenuList>
          </Paper>

          {/* Qn. Type 1(Multiple Choice Qno) */}

          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Add new Question"}</DialogTitle>
            <DialogContent>
              <form
                autoComplete="off"
                id="addQuestion"
                onSubmit={handleFormSubmit}
              >
              <Typography variant="h6"gutterBottom>Question:</Typography>
                <Editor
                  initialValue="<h4>Qno. </h4>"
                  apiKey="kck6txzugrflg6zurhyj1u89qzelzx05spfjcpueey21fa95"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={(e) => setQuestion(e)}
                />
                <p className="text-center text-secondary">OR</p>
                {img.imgUpload !== "" ? (
                  <img
                    src={img.imgUpload}
                    class="img-fluid"
                    alt="Responsive image"
                  />
                ) : null}
                <input
                  type="file"
                  onChange={(e) => handleImgChange(e)}
                  className="mt-4"
                />

                <div className="ml-5 mr-5">
                  <TextField
                    required
                    id="marks"
                    label="Marks"
                    name="marks"
                    type="number"
                    variant="outlined"
                    className="mt-3 "
                    value={marks}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <FormControl className="container mt-2 ">
                    <InputLabel>Select a section</InputLabel>
                    <Select
                      id="select-section"
                      value={section}
                      onChange={(e) => handleSectionChange(e)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {sections.map(({ sectionid, section, isActive }) =>
                        isActive === 1 ? (
                          <MenuItem
                            key={sectionid}
                            value={section}
                            onClick={() => setSectionId(sectionid)}
                          >
                            {section}
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    id="answer_optionA"
                    label="optionA"
                    name="optionA"
                    fullWidth
                    type="text"
                    className="mt-2 mb-2"
                    value={optionA}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                    id="answer_optionB"
                    label="optionB"
                    name="optionB"
                    fullWidth
                    type="text"
                    className="mt-2 mb-2"
                    value={optionB}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                    id="answer_optionC"
                    label="optionC"
                    name="optionC"
                    fullWidth
                    type="text"
                    className="mt-2 mb-2"
                    value={optionC}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                    id="answer_optionD"
                    label="optionD"
                    name="optionD"
                    fullWidth
                    type="text"
                    className="mt-2 mb-2"
                    value={optionD}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <FormControl className="container mt-4 mb-4">
                    <InputLabel>Correct Answer</InputLabel>
                    <Select
                      id="select-option"
                      value={correctAns}
                      onChange={handleCorrectAns}
                    >
                      <MenuItem value="a">a</MenuItem>
                      <MenuItem value="b">b</MenuItem>
                      <MenuItem value="c">c</MenuItem>
                      <MenuItem value="d">d</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form>
            </DialogContent>
            {valueError ? (
              <p className="text-small text-danger ml-4">{valueError}</p>
            ) : (
              <p></p>
            )}
            <DialogActions>
              <Button
                onClick={handleFormSubmit}
                color="primary"
                form="addQuestion"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Qn. Type 2(Descriptive Qno) */}

          <Dialog
            open={open2}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose2}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Add new Question"}</DialogTitle>
            <DialogContent>
              <form
                autoComplete="off"
                id="addQuestion2"
                onSubmit={handleFormSubmit2}
              >
              <Typography variant="h6"gutterBottom>Question:</Typography>

                <Editor
                  initialValue="<h4>Qno. </h4>"
                  apiKey="kck6txzugrflg6zurhyj1u89qzelzx05spfjcpueey21fa95"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={(e) => setQuestion(e)}
                />
                <TextField
                  required
                  id="marks2"
                  label="Marks2"
                  name="marks2"
                  type="number"
                  variant="outlined"
                  className="mt-3 "
                  value={marks2}
                  onChange={(e) => handleInputChange2(e)}
                />
                <FormControl className="container mt-2 ">
                  <InputLabel>Select a section</InputLabel>
                  <Select
                    id="select-section"
                    value={section}
                    onChange={(e) => handleSectionChange(e)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sections.map(({ sectionid, section, isActive }) =>
                      isActive === 1 ? (
                        <MenuItem
                          key={sectionid}
                          value={section}
                          onClick={() => setSectionId(sectionid)}
                        >
                          {section}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            {valueError ? (
              <p className="text-small text-danger ml-4">{valueError}</p>
            ) : (
              <p></p>
            )}
            <DialogActions>
              <Button
                onClick={handleFormSubmit2}
                color="primary"
                form="addQuestion2"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

              {/* Qn. Type 3(Notes) */}

          <Dialog
            open={open3}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose3}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Add new Note"}</DialogTitle>
                
            <DialogContent>
              <form
                autoComplete="off"
                id="addQuestion3"
                onSubmit={handleFormSubmit3}
              >
              <Typography variant="h6"gutterBottom>Title/Question</Typography>
              <TextareaAutosize
                required
                id="title"
                label="title"
                name="title"
                type="text"
                className="mt-2 mb-2 h-2"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                aria-label="minimum height"
                style={{ width: "100%", minHeight: '10%', border: '3px solid #cccccc', padding: '7px'}}
                placeholder="    Type Title
                
                "
              />
              <Typography variant="h6"gutterBottom>Description:</Typography>
                <Editor
                  initialValue="<h4>Qno. </h4>"
                  apiKey="kck6txzugrflg6zurhyj1u89qzelzx05spfjcpueey21fa95"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={(e) => setContent(e)}
                />
              <Typography className="p-5 text-center font-bold" variant="h5"gutterBottom>OR</Typography>
                {img.imgUpload !== "" ? (
                  <>
                  <Typography variant="h6"gutterBottom>Solution:</Typography>
                  <img
                    src={img.imgUpload}
                    class="img-fluid"
                    alt="Responsive image"
                  />
                  </>
                ) : null}
                <input
                  type="file"
                  onChange={(e) => handleImgChange(e)}
                  className="mt-4"
                />
              </form>
            </DialogContent>
            {valueError ? (
              <p className="text-small text-danger ml-4">{valueError}</p>
            ) : (
              <p></p>
            )}
            <DialogActions>
              <Button
                onClick={handleFormSubmit3}
                color="primary"
                form="addQuestion3"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Qn. Type 4(Image Qno) */}

          <Dialog
            open={open4}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose4}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Upload the image"}</DialogTitle>
            <DialogContent>
              <form
                autoComplete="off"
                id="addQuestion4"
                onSubmit={handleFormSubmit4}
              >
                {img.imgUpload !== "" ? (
                  <img
                    src={img.imgUpload}
                    class="img-fluid"
                    alt="Responsive image"
                  />
                ) : null}
                <input
                  type="file"
                  onChange={(e) => handleImgChange(e)}
                  className="mt-4"
                />
                <TextField
                  required
                  id="marks"
                  label="Marks"
                  name="marks"
                  type="number"
                  variant="outlined"
                  className="mt-3 "
                  value={marks}
                  onChange={(e) => handleInputChange(e)}
                />
                <FormControl className="container mt-2 ">
                  <InputLabel>Select a section</InputLabel>
                  <Select
                    id="select-section"
                    value={section}
                    onChange={(e) => handleSectionChange(e)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sections.map(({ sectionid, section, isActive }) =>
                      isActive === 1 ? (
                        <MenuItem
                          key={sectionid}
                          value={section}
                          onClick={() => setSectionId(sectionid)}
                        >
                          {section}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
                <FormControl className="container mt-4 mb-4">
                  <InputLabel>Correct Answer</InputLabel>
                  <Select
                    id="select-option"
                    value={correctAns}
                    onChange={handleCorrectAns}
                  >
                    <MenuItem value="a">a</MenuItem>
                    <MenuItem value="b">b</MenuItem>
                    <MenuItem value="c">c</MenuItem>
                    <MenuItem value="d">d</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            {valueError ? (
              <p className="text-small text-danger ml-4">{valueError}</p>
            ) : (
              <p></p>
            )}
            <DialogActions>
              <Button
                onClick={handleFormSubmit4}
                color="primary"
                form="addQuestion4"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}
