import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import LDrawer from "./LDrawer";

import ViewClients from './ViewClients'

import { getCategories, readUser4Admin, useAuthState, readUser4Client, getFilteredTests, getSubjects, getLinks } from "../../context";


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    background:"#ffff",
    boxShadow: "2px 3px 10px #3f51b5",
    marginBottom: "12px"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function DashboardData() {
    const [{users}, dispatch] = useAuthState()
    useEffect(() => {
        getFilteredTests(dispatch)
        readUser4Client(dispatch)
        readUser4Admin(dispatch)
        getSubjects(dispatch)
        getCategories(dispatch)
        getLinks(dispatch)
    },[])
  const classes = useStyles();

    const admins = []
    const subAdmins = []
    const clients = []


    for (let i in users.filter((u)=>u.isActive===1)){
      users[i].level === 1 ? subAdmins.push(users[i]) : 
      users[i].level === 2 || users[i].level === 4 ? clients.push(users[i]) : 
       admins.push(users[i]) 
    };

  return (
    <div className={classes.root}>
      <LDrawer admins={admins} subAdmins={subAdmins}/>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: false,
        })}
      >
        <div className={classes.drawerHeader} />
        <Grid container spacing={3} className="mt-3">

       
          <Grid item xs={12}> 
         
          <Paper className={classes.paper}> 
              <ViewClients clients={clients}/>
            </Paper> 
        </Grid>        
        </Grid>
      </main>
    </div>
  );
}
