import React, { useEffect, useState } from "react";
import { Signup, useAuthState } from "../context";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import "./Register.css";
import Alert from "@material-ui/lab/Alert";

import { Link, Redirect } from "react-router-dom";
import { Loading } from "../components/Loading";

export default function Register() {
  const [formError, setFormError] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    try {
      localStorage.clear();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onSubmit = (data, e) => {
    setFormError("");
    e.preventDefault();
    state !== "" && district !== "" ?
    data.password === data.Confirmpassword
      ? Signup(dispatch, data)
      : setFormError("Passwords didn't match!")
      : setFormError("Enter all the fields")
  };

  const [{ isRegistered, loading }, dispatch] = useAuthState();
  if (isRegistered) return <Redirect to="/login" />;

  return (
    <div className="container register-card mt-5 rounded">
      <div className="row py-5 mx-2 mt-4 align-items-center">
        <div className="col-md-5 pr-lg-5 mb-5 mb-md-0">
          <img
            src="https://res.cloudinary.com/mhmd/image/upload/v1569543678/form_d9sh6m.svg"
            alt="logo"
            className="img-fluid mb-3 d-none d-md-block"
          />
          <h1>Create an Account</h1>
        </div>

        <div className="col-md-7 col-lg-6 ml-auto mt-5">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
              <div className="input-group col-lg-6 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-user text-muted"></i>
                  </span>
                </div>

                <input
                  required
                  id="firstName"
                  type="text"
                  name="fname"
                  className="form-control bg-white border-left-0 border-md"
                  aria-describedby="Enter First name"
                  placeholder=" First name"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your First Name",
                    },
                  })}
                />
                {errors.fname && (
                  <Alert
                    severity="warning"
                    className="errorMessage mandatory text-error"
                  >
                    {errors.fname.message}
                  </Alert>
                )}
              </div>

              <div className="input-group col-lg-6 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-user text-muted"></i>
                  </span>
                </div>

                <input
                  required
                  id="lastName"
                  type="text"
                  name="lname"
                  className="form-control bg-white border-left-0 border-md"
                  aria-describedby="Enter Last name"
                  placeholder=" Last name"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your Last Name",
                    },
                  })}
                />
                {errors.lname && (
                  <Alert
                    severity="warning"
                    className="errorMessage mandatory text-error"
                  >
                    {errors.lname.message}
                  </Alert>
                )}
              </div>

              <div className="input-group col-lg-12 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-envelope text-muted"></i>
                  </span>
                </div>

                <input
                  required
                  id="inputForEmail"
                  type="email"
                  name="email"
                  className="form-control bg-white border-left-0 border-md"
                  aria-describedby="Enter email address"
                  placeholder="Enter email address"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your email address",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid email address",
                    },
                    minLength: {
                      value: 6,
                      message: "Minimum 6 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {errors.email && (
                  <Alert severity="warning" className="errorMessage mandatory">
                    {errors.email.message}
                  </Alert>
                )}
              </div>

              <div className="input-group col-lg-12 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-phone-square text-muted"></i>
                  </span>
                </div>

                <input
                  required
                  id="inputForPhone"
                  type="tel"
                  name="contact"
                  className="form-control bg-white border-md border-left-0 pl-3"
                  aria-describedby="Enter Phone no"
                  placeholder="Phone no"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your Phone no",
                    },
                    pattern: {
                      value: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/i,
                      message: "Enter a valid Phone Number",
                    },
                  })}
                />
                {errors.contact && (
                  <Alert severity="warning" className="errorMessage">
                    {errors.contact.message}
                  </Alert>
                )}
              </div>
              <div className="input-group col-lg-6 mb-4">
                <select
                  name="state"
                  id="state"
                  value={state}
                  class="form-control border-md"
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="">State</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadar and Nagar Haveli">
                    Dadar and Nagar Haveli
                  </option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
              </div>

              <div className="input-group col-lg-6 mb-4">
                <input
                  required
                  id="lastName"
                  type="text"
                  name="lname"
                  className="form-control bg-white border-md"
                  aria-describedby="Enter Last name"
                  placeholder="District"
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </div>
              <div className="input-group col-lg-6 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-lock text-muted"></i>
                  </span>
                </div>

                <input
                  required
                  type="password"
                  name="password"
                  className="form-control bg-white border-left-0 border-md"
                  id="inputForPassword"
                  placeholder="Enter password"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter password",
                    },
                    minLength: {
                      value: 8,
                      message: "Minimum 8 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {errors.password && (
                  <Alert
                    severity="warning"
                    className="errorMessage mandatory text-error"
                  >
                    {errors.password.message}
                  </Alert>
                )}
              </div>

              <div className="input-group col-lg-6 mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white px-4 border-md border-right-0">
                    <i className="fa fa-lock text-muted"></i>
                  </span>
                </div>

                <input
                  type="password"
                  name="Confirmpassword"
                  className="form-control bg-white border-left-0 border-md"
                  id="inputForConfirmPassword"
                  placeholder="Confirm Password"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please confirm your password",
                    },
                    minLength: {
                      value: 8,
                      message: "Minimum 8 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {errors.Confirmpassword && (
                  <Alert severity="warning" className="errorMessage">
                    {errors.Confirmpassword.message}
                  </Alert>
                )}
              </div>
              {formError !== "" ? (
                <p style={{ color: "red" }}>{formError}</p>
              ) : (
                <p></p>
              )}
              <div className="form-group col-lg-12 mx-auto mb-0">
                <button
                  type="submit"
                  className="btn btn-primary btn-block py-2"
                >
                  {loading ? <Loading /> : "Register"}
                </button>
              </div>

              <div className="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
                <div className="border-bottom w-100 ml-5"></div>
                <span className="px-2 small text-muted font-weight-bold text-muted">
                  OR
                </span>
                <div className="border-bottom w-100 mr-5"></div>
              </div>
              <div className="text-center w-100">
                <Link
                  className="text-muted font-weight-bold"
                  to="/login"
                  variant="body2"
                >
                  {"Already have an account? Sign In"}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
