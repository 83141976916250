import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import parse from 'html-react-parser'
import PublishIcon from "@material-ui/icons/Publish";

import {
  createSubmission,
  useAuthState,
  viewQuestions,
  getSections,
} from "../../context";
import TestRedirect from "./TestRedirect";
import SimpleNav from "../SimpleNav";
import { Redirect } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Button, TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

function TestView() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [{ questions, loading, sections }, dispatch] = useAuthState();
  const [selected, setSelected] = React.useState([]);
  const [helperText, setHelperText] = React.useState("Choose wisely");
  const [_answer, setAnswer] = useState('')
  const [error, setError] = useState(false);
  const testid = localStorage.getItem("testid");
  const startTestTime = localStorage.getItem("startTestTime");
  const endTestTime = localStorage.getItem("endTestTime");
  const timer = parseInt(localStorage.getItem("timer"))
  const [minutes, setMinutes] = useState(parseInt(localStorage.getItem("min")));
  const [seconds, setSeconds] = useState(parseInt(localStorage.getItem("sec")));



  useEffect(() => {
    try {
      if (testid == null || testid == undefined) {
        setError(true)
      }else{
        var testsGiven = JSON.parse(localStorage.getItem("testsGiven"));
        testsGiven.push(parseInt(testid));
        let testSet = new Set(testsGiven);
        testsGiven = Array.from(testSet);
        localStorage.setItem("testsGiven", JSON.stringify(testsGiven));
      }
    } catch (err) {
      setError(true);
    }
    
  }, []);

  useEffect(()=>{
    viewQuestions(dispatch, testid);
    getSections(dispatch, testid);
    try {
      setTimeout(function () {
        try{
        document.getElementById("btnSubmit").click();
        }catch(err){
          setError(true)
        }
      }, (timer * 60000) + 5000);
    } catch (err) {
      console.log(err);
    }
  },[])

  useEffect(() => {
    window.onbeforeunload = function() {
        return true;
    };

    return () => {
        window.onbeforeunload = null;
    };
}, []);
  const selector = React.useCallback(
    (key, ans, section) =>
      setSelected((selected) => ({
        ...selected,
        [key]: {
          ...selected[key],
          qid: key,
          ans,
          section,
        },
      })),
    []
  );

  const answers = Object.assign(
    {},
    ...questions.map((x) => ({ [x.qid]: x.correctAns }))
  );

  const marks = Object.assign(
    {},
    ...questions.map((x) => ({ [x.qid]: x.marks }))
  );

  const getMarks = () => {
    let totalmarks = 0;
    for (let i in selected) {
      if (selected[i].ans === answers[i]) {
        totalmarks = totalmarks + marks[i];
      }
    }
    return totalmarks;
  };

  const sectionMarks = (sec) => {
    let sectionMarks = 0;
    for (let i in selected) {
      if (selected[i].section === sec) {
        if (selected[i].ans === answers[i]) {
          sectionMarks = sectionMarks + marks[i];
        }
      }
    }
    return sectionMarks;
  };

  const getTotalSecMark = (section) => {
    const quesBySec = questions.filter((sec) => sec.section === section);
    var totalSecMarks = 0;
    for (let i in quesBySec) {
      totalSecMarks += quesBySec[i].marks;
    }
    return totalSecMarks;
  };

  const handleRadioChange = (e, qid, section) => {
    const data = e.target.value;
    selector(qid, data, section);
    setHelperText(" ");
  };

  const handleSubmit = (e) => {
    const sectionWise = [];
    const result = getMarks();
    const answers = Object.values(selected);
    const secs = sections.filter((sec) => sec.isActive === 1);
    for (let i in secs) {
      const sectionTotal = getTotalSecMark(secs[i].section);
      const achieved = sectionMarks(secs[i].section);
      sectionWise.push({
        section: secs[i].section,
        sectionTotal: sectionTotal,
        marksAchieved: achieved,
      });
    }
    try{
      e.preventDefault();
    } catch{
      console.log("error")
    }
    if(testid != null)
    createSubmission(
      dispatch,
      testid,
      result,
      answers,
      startTestTime,
      endTestTime,
      sectionWise
    );
      setOpen(true)
  };

  useEffect(() => {
    if (!seconds) return;
    const intervelID = setInterval(() => {
      setSeconds(parseInt(localStorage.getItem("sec")));
      setMinutes(parseInt(localStorage.getItem("min")));
    }, 1000);

    return () => {
      
      clearInterval(intervelID);
    };
  }, [seconds]);
  

  if (error || !testid ) return <Redirect to="/subject-test-view" />;

  return (
    <div>
      {questions.length === 0 ? (
        <>
          <SimpleNav heading={"Attempt all the questions:"} />

          <div className="container mt-5 ">
            <br />
            <Skeleton className="bg-dark" variant="rect" height={165} />
            <Skeleton className="bg-dark" variant="rect" height={165} />
            <Skeleton className="bg-dark" variant="rect" height={50} />
            <br />
            <Skeleton className="bg-dark" variant="rect" height={165} />
            <Skeleton className="bg-dark" variant="rect" height={165} />
          </div>
        </>
      ) : (
        <>
              <SimpleNav heading={"Attempt all the questions:"} />
              <form
                className="container"
                style={{ marginTop: "120px" }}
                id="form"
                onSubmit={handleSubmit}
              >
                {questions.map(
                  (
                    {
                      question,
                      optionA,
                      optionB,
                      optionC,
                      optionD,
                      qid,
                      marks,
                      isActive,
                      section,
                      title,
                      content,
                      type,
                      img,
                    },
                    index
                  ) =>
                    isActive === 1 ? (
                      <div className="card mt-3 rounded-lg shadow-lg" key={qid}>
                      {type !== 3 ?
                              img && img !== "" ? (
                                <img
                                  src={img}
                                  className="mx-auto p-2 d-block"
                                  style={{ maxHeight: '550px', maxWidth: '75%'}}
                                  alt="img"
                                />
                              ) : (
                                  <div className="p-2">{parse(question)}</div>
                              ):
                              <div className="card-header p-2">{title}</div>
                      }

                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            {marks > 0 ? <p>{marks} marks</p> : null}
                            {section === "" || section === null ? null : (
                              <p>{section}</p>
                            )}
                          </div>
                          {type === 3 ? (
                            <div className="container">
                            {parse(content)}
                            </div>
                          ) : type === 2 ? 
                          <TextareaAutosize
                            required
                            id="_answer"
                            label="_answer"
                            name="_answer"
                            type="text"
                            className="mt-3 h-2"
                            value={_answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            aria-label="minimum height"
                            style={{
                              width: "100%",
                              minHeight: "20%",
                              border: "3px solid #cccccc",
                              padding: "7px",
                            }}
                            placeholder="      Type Answer
                            
                            "
                          />
                          :(
                            <FormControl
                              component="fieldset"
                              className={classes.formControl}
                            >
                              <RadioGroup
                                aria-label="quiz"
                                name="quiz"
                                value={selected[index]}
                                onChange={(e) =>
                                  handleRadioChange(e, qid, section)
                                }
                              >
                                <FormControlLabel
                                  value="a"
                                  control={<Radio />}
                                  label={optionA || "(a)"}
                                />
                                <FormControlLabel
                                  value="b"
                                  control={<Radio />}
                                  label={optionB || "(b)"}
                                />
                                <FormControlLabel
                                  value="c"
                                  control={<Radio />}
                                  label={optionC || "(c)"}
                                />
                                <FormControlLabel
                                  value="d"
                                  control={<Radio />}
                                  label={optionD || "(d)"}
                                />
                              </RadioGroup>
                              <FormHelperText>{helperText}</FormHelperText>
                            </FormControl>
                          )}
                        </div>
                      </div>
                    ) : null
                )}
                {questions.length !== 0 ?
                <div className="container text-center">
                  <Button
                   id="btnSubmit"
                    variant="contained"
                    className="flex justify-content-center m-5"
                    color="primary"
                    size="large"
                    startIcon={<PublishIcon />}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>:null
                  
            }
            
              </form>
              {open ? 
              <div className="d-flex justify-content-center mt-5 mb-3">
                  <TestRedirect />
              </div>:
              null
            }
        </>
      )}
    </div>
  );
}

export default TestView;