import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useAuthState, getTests4Client } from "../../context";
import Nav from "./Nav";
import { TestCard } from "./TestCard";
import History from "../History";
import { Redirect } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Loading } from "../Loading";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  head: {
    color: "#3f51b5",
    fontFamily: "'Times New Roman', Times, serif",
    fontWeight: 600,
  },
  bodyCard: {
    display: "flex",
    marginTop: 10,
    // background:"#93d8d8b4",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0),
      width: theme.spacing(46),
      height: theme.spacing(10),
    },
  },
  main: {
    flexGrow: 1,
    marginTop: 80,
  },
  paper: {
    padding: theme.spacing(4),
    fontWeight: 700,
    textAlign:"center",
    whiteSpace: "unset",
    height:"140px",
    background: "#fff",
    borderBottom: "1px solid #c5e9e9bd",
    fontSize: 17,
    boxShadow: "7px 8px 10px #2222",
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "7px 8px 10px #3f51b5",
      color: "#3f51b5",
      cursor: "pointer",
    },

    "&:active": {
      color: "#acb1c5",
      backgroundColor: "#b8daff",
    },
  },
  btn:{
    marginTop:"12px",
    width:"100%"
  }
}));


function SelectSubject() {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [{ subjects, loading }, dispatch] = useAuthState();
  const [error, setError] = useState(false);
  const category = localStorage.getItem("category");
  const categoryid = localStorage.getItem("categoryid");
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (categoryid === null || category === null || categoryid === undefined || category === undefined) setError(true);
    try {
      localStorage.removeItem("testid");
      localStorage.removeItem("testtime");
      localStorage.removeItem("testname");
      localStorage.removeItem("totalMarks");
      localStorage.removeItem("timer");
      localStorage.removeItem("testObject");
    } catch (err) {
      console.log(err);
    }
    dispatch({
      type: "GET_QUESTIONS",
      questions: [],
    })
  }, [categoryid, category]);

  const handleClick = (subid) => {
    getTests4Client(dispatch, subid);
    setLoad(false);
    setShow(true);
  };

  if (error) return <Redirect to="/stud-dashboard" />;

  return (
    <>
      <Nav />
        <div>
          <div className={classes.main}>
            <div className="container">
              <History history={category} />
              {loading && load ? (
                <Loader />
              ) : (
                <div className={classes.bodyCard}>
                  {subjects.filter((subject) => subject.isActive === 1).length === 0 ? (
                    <h3 className="mt-4 p-3 text-secondary border border-info">
                      No subjects available!
                    </h3>
                  ) : (
                    <>
                      {subjects.filter((subject) => subject.isActive === 1 && subject.isVisible).map(({ subname, subid }) => (
                        <Grid container spacing={4} key={subid} className="mb-5 mt-4"> 
                          <Grid item xs={12}>
                            <Paper
                              className={classes.paper}
                              onClick={() => handleClick(subid)}
                            >
                             {subname}
                             <br/>
                         <Button className={classes.btn} color="primary" variant="contained" size="small">View Tests</Button>
                            </Paper> 
                             
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {show ? (
            loading ? (
              <div className="mt-5 pt-5">
                <hr />
                <Loading />
              </div>
            ) : (
              <TestCard />
            )
          ) : (
            <></>
          )}
        </div>
    </>
  );
}

export default SelectSubject;

const Loader = () => {
  return(
    <div className="container mt-3">
    <div className="row mb-5">
      <div className="col-sm-4 mb-5">
        <Skeleton variant="rect" height={65} />
      </div>
      <br />
      <div className="col-sm-4 mb-5">
        <Skeleton variant="rect" height={65} />
      </div>
      <br />
      <div className="col-sm-4 mb-5">
        <Skeleton variant="rect" height={65} />
      </div>
      <br />
    </div>
  </div>
  )
  
};
