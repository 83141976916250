import axios from "axios";
import { readUser4Client } from "..";

const baseURL = "https://tapapi.shivalikcollege.edu.in";
// const baseURL = "http://127.0.0.1:5001";


export const adminLogin = async (dispatch, { username, password }) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    username,
    password,
  };
  try {
    const res = await axios.post(`${baseURL}/login`, body, config);
    if (res.data[0].level === 3) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      });
      readUser4Client(dispatch);
    } else
      dispatch({
        type: "LOGIN_FAIL",
      });
  } catch (err) {
    dispatch({
      type: "LOGIN_FAIL",
    });
  }
};

export const createAdmin = async (
  dispatch,
  { username, fname, lname, contact, email, password }
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    document: {
      username: username,
      fname: fname,
      lname: lname,
      contact: contact,
      email: email,
      password: password,
      createdBy: -1,
      isActive: 1,
      level: 3,
    },
  };
  try {
    const res = await axios.post(`${baseURL}/register`, body, config);
    dispatch({
      type: "SIGNUP_SUCCESS",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "SIGNUP_FAIL",
    });
  }
};

export const createSubAdmin = async (
  dispatch,
  { username, fname, lname, contact, email, password }
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    document: {
      username: username,
      fname: fname,
      lname: lname,
      contact: contact,
      email: email,
      password: password,
      createdBy: -1,
      isActive: 1,
      level: 1,
    },
  };
  try {
    const res = await axios.post(`${baseURL}/register`, body, config);
    dispatch({
      type: "SIGNUP_SUCCESS",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: "SIGNUP_FAIL",
    });
  }
};

export const readUser4Admin = async (dispatch) => {
  dispatch({
    type: "START_LOAD",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    Filter: {},
  };

  try {
    const res = await axios.post(`${baseURL}/readUsers`, body, config);
    dispatch({
      type: "GET_USERS",
      users: res.data,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateAdminSiteUser = async (
  dispatch,
  fname,
  lname,
  contact,
  email,
  iuid
) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    Filter: {
      uid: iuid,
    },
    DataToBeUpdated: {
      fname,
      lname,
      contact,
      email,
    },
  };
  try {
    await axios.post(`${baseURL}/updateUser`, body, config);
    readUser4Admin(dispatch);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const subjectCreate = async (dispatch, subname, categoryid) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "subjects",
    document: {
      subname,
      categoryid,
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
      isVisible: true
    },
  };

  try {
    await axios.post(`${baseURL}/createSubject`, body, config);
    getSubjects(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getSubjects = async (dispatch) => {
  dispatch({
    type: "START_LOADING",
  });
  const subjects = [];
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "subjects",
    Filter: {
      // createdBy: localStorage.getItem("user.uid"),
    },
  };

  try {
    const res = await axios.post(`${baseURL}/getSubjects`, body, config);
    res.data.map((subject) => subjects.push(subject));
    dispatch({
      type: "GET_SUBJECTS",
      subjects: subjects,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateSubject = async (dispatch, subid, subname) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "subjects",
    Filter: {
      subid,
    },
    DataToBeUpdated: {
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
      subid,
      subname,
    },
  };
  try {
    const res = await axios.post(`${baseURL}/updateSubject`, body, config);
    getSubjects(dispatch);
    res.data.status === "1"
      ? dispatch({
          type: "ACTION_SUCCESS",
        })
      : dispatch({
          type: "ACTION_FAIL",
        });
    getSubjects(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const createCategory = async (dispatch, categoryName, categoryType) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "category",
    document: {
      categoryName,
      categoryType: parseInt(categoryType),
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
      isVisible: true
    },
  };

  try {
    await axios.post(`${baseURL}/createCategory`, body, config);
    getCategories(dispatch);
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
  }
}

export const getCategories = async (dispatch, categoryid) => {
  const categories = [];
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "category",
    Filter: {
      categoryid,
      // createdBy: localStorage.getItem("user.uid"),
    },
  };

  try {
    const res = await axios.post(`${baseURL}/viewCategory`, body, config);
    res.data.map((category) => {
      return categories.push(category);
    });
    dispatch({
      type: "GET_CATEGORY",
      categories: categories,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const updateCategory = async (dispatch, categoryid, categoryName, categoryType) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "category",
    Filter: {
      categoryid,
    },
    DataToBeUpdated: {
      isActive: 1,
      categoryName,
      categoryType,
      createdBy: parseInt(localStorage.getItem("user.uid")),
    },
  };
  try {
    const res = await axios.post(`${baseURL}/updateCategory`, body, config);
    res.data.status === 1
      ? dispatch({
          type: "ACTION_SUCCESS",
        })
      : dispatch({
          type: "ACTION_FAIL",
        });
        getCategories(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
}

export const createLink = async (dispatch, link, linktitle) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "links",
    document: {
      link,
      linktitle,
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
    }
  };

  try {
    await axios.post(`${baseURL}/createLink`, body, config);
    dispatch({
      type: "ACTION_SUCCESS",
    });
    getLinks(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getLinks = async (dispatch) => {
  const links = [];
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "links",
    Filter: {
      // createdBy: localStorage.getItem("user.uid"),
    },
  };

  try {
    const res = await axios.post(`${baseURL}/getLinks`, body, config);
    res.data.map((link) => {
      return links.push(link);
    });
    dispatch({
      type: "GET_LINKS",
      links: links,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateLink = async (dispatch, linkid, link, linktitle) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "links",
    Filter: {
      linkid,
    },
    DataToBeUpdated: {
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
      link,
      linktitle,
    },
  };
  try {
    const res = await axios.post(`${baseURL}/updateLink`, body, config);
    res.data.status === 1
      ? dispatch({
          type: "ACTION_SUCCESS",
        })
      : dispatch({
          type: "ACTION_FAIL",
        });
        getLinks(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const createMessage = async (dispatch, message) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "message",
    document: {
      message,
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
    }
  };

  try {
    await axios.post(`${baseURL}/createSection`, body, config);
    dispatch({
      type: "ACTION_SUCCESS",
    });
    getMessages(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getMessages = async (dispatch) => {
  const messages = [];
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "message",
    Filter: {
      // createdBy: localStorage.getItem("user.uid"),
      isActive:1
    },
  };

  try {
    const res = await axios.post(`${baseURL}/getSections`, body, config);
    res.data.map((message) => {
      return messages.push(message);
    });
    dispatch({
      type: "GET_MESSAGES",
      messages: messages,
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const updateMessage = async (dispatch, messageid, message) => {
  dispatch({
    type: "START_LOADING",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "message",
    Filter: {
      messageid,
    },
    DataToBeUpdated: {
      createdBy: localStorage.getItem("user.uid"),
      isActive: 1,
      message,
    },
  };
  try {
    const res = await axios.post(`${baseURL}/updateSection`, body, config);
    res.data.status === 1
      ? dispatch({
          type: "ACTION_SUCCESS",
        })
      : dispatch({
          type: "ACTION_FAIL",
        });
        getMessages(dispatch);
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });
  }
};

export const notifyUser = async (dispatch, uid, mid, msg) => {
  const result = await getUserMsg(dispatch, uid, mid, msg)
  const u_msg_ids = result.u_msg_ids
  const u_msgs = result.u_msgs
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    Filter: {
      uid,
      isActive:1
    },
    DataToBeUpdated: {
      u_msg_ids,
      u_msgs
  }
  }
  try {
    await axios.post(`${baseURL}/updateUser`, body, config)
    dispatch({
      type: "ACTION_SUCCESS",
    });
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });

  }
};

export const getUserMsg = async (dispatch, uid, mid, msg) => {
  var u_msg_ids = [];
  var u_msgs = [];

  dispatch({
    type: "START_LOAD",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = {
    database: "ExaminationSystem",
    collection: "users",
    Filter: {
      uid,
      isActive:1
    }
  };
  try {
    const res = await axios.post(`${baseURL}/readUsers`, body, config)

    if(res.data[0].mids){
      res.data[0].mids.map((mid) => {
        u_msg_ids.push(mid)
      })
      res.data[0].msgs.map((msg) => {
        u_msgs.push(msg)
      })
      if(!u_msg_ids.includes(mid)){ 
        u_msg_ids.push(mid)
        u_msgs.push(msg)}
    } else {
      u_msg_ids = [mid]
      u_msgs = [msg]
    }
    return{
      u_msg_ids, u_msgs
    } 
    
  } catch (err) {
    dispatch({
      type: "ACTION_FAIL",
    });

  }
};
