import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  useAuthState,
  createMessage,
  updateMessage,
  deleteMessage,
  getMessages,
} from "../../context";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Loading } from "../Loading";
import SendMessage from "./SendMessage";
import DeleteItem from "../SubAdminDashboard/DeleteItem";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    margin: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Messages() {
  const classes = withStyles();
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [{ messages }, dispatch] = useAuthState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createMessage(dispatch, message);
  };

  useEffect(() => {
    getMessages(dispatch)
  },[])
  
  return (
    <div>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <QuestionAnswerIcon />
        </ListItemIcon>
        <ListItemText primary="Messages" />
      </ListItem>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Messages
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit} className="mb-3">
            <div className="d-flex justify-content-around">
              <textarea
                class="form-control"
                style={{ width: "400px" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Add new message"
                required
              />
              <IconButton aria-label="add" type="submit">
                <AddBoxIcon fontSize="large" color="primary" />
              </IconButton>
            </div>
          </form>
          {messages
            .filter((mes) => mes.isActive === 1)
            .map(({ messageid, message }, index) => (
              <Accordion key={messageid}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {index + 1}. &nbsp; {message}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div className="d-flex flex-column">
                  <SendMessage msg={message} mid={messageid}/>
                  <UpdateMessage messageid={messageid} message={message} />
                </div>

                </AccordionDetails>
              </Accordion>
            ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const UpdateMessage = ({ messageid, message }) => {
  const classes = withStyles();

  const [newMessage, setNewMessage] = useState(message);
  const [{ loading }, dispatch] = useAuthState();
  const handleSubmit = (e) => {
    e.preventDefault();
    updateMessage(dispatch, messageid, newMessage);
  };
  return (
    <form onSubmit={handleSubmit} className="mb-3 mt-3 row">
      <div className="col-8 pl-3">
        <textarea
          class="form-control"
          style={{ width: "400px" }}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Add new message"
          required
        />
      </div>

      <div className="col-4 pl-5">
        <div className="text-center mt-3">
        <button type="submit" className="btn btn-primary btn-sm mb-2">
            {loading ? <Loading /> : "Update"}
          </button>
          <DeleteItem
            deleteFun={() => deleteMessage(dispatch, messageid)}
            item={message}
            icon={"redButton"}
          />
        </div>
      </div>
    </form>
  );
};
