import React, { useEffect } from "react";
import { useAuthState, viewResults } from "../../context";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import History from "../History";
import SimpleNav from "../SimpleNav";


export default function SAResults() {
  const testid = localStorage.getItem("testid")
  const [{ results, loading }, dispatch] = useAuthState();
  useEffect(() => {
    viewResults(dispatch, testid);
    console.log(results)
  }, [dispatch]);

  const clickHandler = (testid, submitID) => {
    localStorage.setItem("testid", testid)
    localStorage.setItem("submitID", submitID);
  };
  if(testid === null || testid === 'undefined') return <Redirect to="/sub-admin-dashboard" />
  return (
    <>
      <SimpleNav heading={"Results"} />
      {loading ? (
        <div className="container mt-5">
          <Skeleton variant="rect" height={50} />
          <br />
          <Skeleton variant="rect" height={320} />
        </div>
      ) : (
        <div className="container mt-5">
          <div className="mt-4">
            <History history={""} />
          </div>
          {results.filter((result) => result.isActive === 1).length === 0 ? (
            <h2 className="col-sm-12 text-center text-secondary border border-info p-3 mt-4">
              No Submissions Available!
            </h2>
          ) : (
            <div className="card mt-4 text-center">
              <div className="d-flex justify-content-around p-3">
                <h2>Result</h2>
                <Button>
                  <CSVLink
                    className=" text-left text-decoration-none "
                    data={results.map(
                      ({
                        fname,
                        contact,
                        email,
                        lname,
                        testname,
                        total,
                        result,
                        userid,
                        gender,
                        dob,
                        college,
                        university_no,
                        stream,
                        state,
                        city,
                        utm_source,
                        utm_medium,
                        utm_campaign,
                        scholarshipid
                      }) => ({
                        Name: fname,
                        Contact: contact,
                        Email: lname,
                        Test_Name: testid,
                        Total_Marks: total,
                        Marks_Achieved: result,
                        Gender: gender,
                        DOB: dob,
                        College: college,
                        University_Number: university_no,
                        Erp_Id: email,
                        Student_Id: userid,
                        Stream: stream,
                        State: state,
                        City: city,
                        utm_source: utm_source,
                        utm_medium: utm_medium,
                        utm_campaign: utm_campaign,
                        Scholarshipid: scholarshipid
                      })
                    )}
                  >
                    Download Result
                  </CSVLink>{" "}
                </Button>
              </div>
              <table className="table table-hover card-body">
                <thead className="table-primary">
                  <tr>
                    <th scope="col">Student Name</th>
                    <th scope="col">Test Name</th>
                    <th scope="col">Total Marks</th>
                    <th scope="col">Marks Achieved</th>
                    <th scope="col">Submission</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map(
                    ({
                      result,
                      fname,
                      submissionID,
                      testname,
                      total,
                      testid,
                    }) => (
                      <tr key={submissionID}>
                        <th scope="row">{fname}</th>
                        <th>{testname}</th>
                        <td>{total}</td>
                        <td>{result}</td>
                        <td>
                          <Link to="/view-submission">
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={() => clickHandler(testid, submissionID)}
                            >
                              View
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
}
