import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Settime from "../SubAdminDashboard/Settime";
import axios from "axios";
import { createTest, useAuthState } from "../../context";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    minwidth: 100,
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export default function CreateTest() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [subid, setSubid] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [categoryName, setCategoryName] = useState("");


  const [categoryid, setCategoryId] = useState("");
  const [{ subjects, categories }, dispatch] = useAuthState();
  const [testName, setTestName] = useState("");
  const [testTime, setTestTime] = useState(0);
  const [valueError, setValueError] = useState("");
  const [startTestTime, setStartTestTime] = useState();
  const [endTestTime, setEndTestTime] = useState();

  const handleInputChange = (e) => {
    setSubid(String(e.target.value) || "");
  }

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value)
    getCategoryType(e.target.value)
  }

  const getCategoryType = async (value) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      database: "ExaminationSystem",
      collection: "category",
      Filter: {
        categoryid: value
      },
    };
  
    try {
      const res = await axios.post("https://tapapi.shivalikcollege.edu.in//viewCategory", body, config);
      setCategoryType(res.data[0].categoryType)
    } catch (err) {
      console.log(err)
    }
  }
  const handleTestNameChange = (e) => {
    setTestName(e.target.value);
  };

  const handleTestTimeChange = (e) => setTestTime(e.target.value);

  const handleStartTimeChange = (e) => {
    const today = new Date(e.target.value);
    setStartTestTime(today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
  };

  const handleEndTimeChange = (e) => {
    const today = new Date(e.target.value);
    setEndTestTime(today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (testName === "" || subid === "" || testTime <= 0 || categoryid === "")
      setValueError("Enter the valid values!");
    else {
      setValueError("");
      createTest(
        dispatch,
        testName,
        subid,
        testTime,
        startTestTime,
        endTestTime,
        categoryName,
        categoryid,
        categoryType
      );
      resetForm();
      handleClose();
    }
  };

  const handleCatName = (e, categoryName)=>{
    setCategoryName(categoryName)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setSubid("");
    setCategoryId("");
    setTestName("");
    setTestTime("");
  };
  // const today = new Date(startTestTime);
  return (
    <div>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary={"Create Test"} />
      </ListItem>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <form
          className={classes.container}
          id="test-form"
          noValidate
          autoComplete="off"
        >
          <DialogTitle>Create a new test</DialogTitle>

          <DialogContent>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="testName"
                label="Name of the test"
                name="testName"
                type="text"
                value={testName || ""}
                autoComplete="testName"
                autoFocus
                onChange={(e) => handleTestNameChange(e)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-category-label">Category</InputLabel>
              <Select
                id="select-category"
                defaultValue=""
                value={categoryid}
                onChange={(e) => handleCategoryChange(e)}
                input={<Input id="select-category-label" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categories.map(({ categoryName, categoryid, isActive }) => (
                  isActive === 1 ? 
                  <MenuItem key={categoryid} value={categoryid}
                   onClick={(e) => handleCatName(e, categoryName)}
                  >
                    {categoryName}
                  </MenuItem> :
                  null
                ))}
              </Select>

              
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="select-subject-label">Subject</InputLabel>
              <Select
                id="select-subject"
                defaultValue=""
                value={subid}
                onChange={(e) => handleInputChange(e)}
                input={<Input id="select-subject-label" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {subjects.filter((subject) => subject.categoryid === String(categoryid)).map(({ subname, subid, isActive }) => (
                  isActive === 1 ? 
                  <MenuItem key={subid} value={subid}>
                    {subname}
                  </MenuItem> :
                  null
                ))}
              </Select>

              
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="testTime"
                label="Time limit"
                helperText="(in minutes)"
                name="testTime"
                type="number"
                value={testTime || ""}
                autoComplete="testTime"
                autoFocus
                onChange={(e) => handleTestTimeChange(e)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
            {startTestTime}
              <Settime
                onChange={handleStartTimeChange}
                label="Start Date & Time"
              />
            </FormControl>

            <FormControl className={classes.formControl}>
            {endTestTime}
              <Settime
                onChange={handleEndTimeChange}
                label="End  Date & Time"
              />
            </FormControl>
          </DialogContent>
        </form>
        {valueError ? (
          <p className="text-small text-danger ml-4">{valueError}</p>
        ) : (
          <p></p>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary" form="test-form">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
