export const initialState = {
  isRegistered: false,
  user: [{}],
  users: [{}],
  loading: false,
  load: false,
  subjects: [],
  categories:[],
  tests: [],
  questions: [],
  results: [],
  submission: [],
  links: [],
  sections: [],
  messages: [],
  errorMessage: null,
  u_msg_ids: [],
  onlyClients: []
};


export const reducer = (initialState, action) => {
  switch(action.type) {
      case 'START_LOADING':
        return {
          ...initialState,
          loading: true
        }
      case 'LOAD_USER':
        try{
          localStorage.setItem("testsGiven", JSON.stringify(action.user[0].testsGiven));
          } catch {
            localStorage.setItem("testsGiven", ['']);
          }
          localStorage.setItem("contact", action.user[0].contact || null);
          localStorage.setItem("email", action.user[0].email || null);
          localStorage.setItem("lname", action.user[0].lname || null);
          localStorage.setItem("fname", action.user[0].fname || null);
          localStorage.setItem("college", action.user[0].college || null);
          localStorage.setItem("gender", action.user[0].gender || null);
          localStorage.setItem("university_no", action.user[0].university_no || null);
          localStorage.setItem("address", action.user[0].address || null);
          localStorage.setItem("dob", action.user[0].dob || null);
          if(action.user[0].u_msgs)
          localStorage.setItem("notifications", JSON.stringify(action.user[0].u_msgs))
        return{
          ...initialState,
          load: false,
          loading: false,
          user: action.user,
        }
      case 'GET_USERS' :
        return{
            ...initialState,
            load: false,
            loading: false,
            users: action.users
        }

      case 'LOGIN_SUCCESS':
        localStorage.setItem('user.level', action.payload[0].level);
        localStorage.setItem('user.uid', action.payload[0].uid);
        localStorage.setItem('isAuthenticated', true);
        return {
          ...initialState,
          loading: false,
          user: action.payload
        }
      case 'SIGNUP_SUCCESS':
        return {
          ...initialState,
          user: action.payload,
          loading: false,
          isRegistered: true,
        }
      case 'SIGNUP_FAIL':
      case 'LOGIN_FAIL':
        return {
          ...initialState,
          user: null,
          loading: false,
          errorMessage: "Your creds didn't match! Try Again.."
        }
      case 'LOGOUT':
        return {
          ...initialState,
          user: null,
          loading: false,
        }
      case 'GET_SUBJECTS':
        return {
          ...initialState,
          subjects: action.subjects,
          loading: false
        }
      case 'GET_TESTS':
        return {
          ...initialState,
          tests: action.tests,
          loading: false
        }
      case 'GET_SECTIONS':
        return {
          ...initialState,
          sections: action.sections,
          loading: false
        }
      case 'GET_QUESTIONS':
        return {
          ...initialState,
          questions: action.questions,
          loading: false
        }
      case 'GET_LINKS':
        return {
          ...initialState,
          links: action.links,
          loading: false
        }
      case 'ACTION_SUCCESS':
        return {
          ...initialState,
          load: false
        }
      case 'ACTION_FAIL':
        return {
          ...initialState,
          loading: false,
          load: false
        }
      case 'UPDATE_SUBJECT': 
      return {
        ...initialState,
        loading: false
      };
      case 'ACTION_FAILED': 
      return {
        ...initialState,
        loading: false,
        load: false
      };
      case 'QUESTION_CREATED': 
      return {
        ...initialState,
        loading: false
      };
      case 'GET_CATEGORY': 
      return {
        ...initialState,
        categories: action.categories,
        loading: false
      };
      case 'START_LOAD': 
      return {
        ...initialState,
        load: true,
      }   
      case 'SUBMISSION_SUCCESS': 
      return {
        ...initialState,
        load: false,
      }
      case 'SUBMISSION_FAIL': 
      return {
        ...initialState,
        load: false,
        loading: false
      }
      case 'GET_RESULTS': 
      return {
        ...initialState,
        loading: false,
        results: action.results
      };
      case 'GET_MESSAGES': 
      return {
        ...initialState,
        loading: false,
        messages: action.messages
      };
      case 'GET_SUBMISSION': 
      return {
        ...initialState,
        submission: action.submission,
        loading: false,
      }
      case 'GET_USER_MSG_IDS': 
        return {
          ...initialState,
          u_msg_ids: action.u_msg_ids,
          load: false
        }
      case 'GET_ONLY_CLIENTS':
        return{
          ...initialState,
          onlyClients: action.onlyClients,
          load: false
        }

      default:
        return initialState
  }
}