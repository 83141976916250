import React, { useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import History from "../History";
import Nav from "./Nav";
import { useAuthState } from "../../context";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { dispatchMessages } from "../../context/Actions/ClientActions";

const Notifications = () => {
  const [{ loading }, dispatch] = useAuthState();
  var user_msgs
  JSON.parse(localStorage.getItem("notifications")) == undefined ? user_msgs = [] : user_msgs = JSON.parse(localStorage.getItem("notifications"))



  return (
    <>
      <Nav />
      {loading ? (
        <div className="container">
          <Skeleton variant="rect" height={50} />
          <br />
          <Skeleton variant="rect" height={295} />
        </div>
      ) : (
        <div className="container ">
          <History history={""} />
          {user_msgs.length === 0 ? (
            <h4 className="col-sm-12 text-center text-secondary border border-info p-3 mt-5">
              No Notifications Available!
            </h4>
          ) : (
            user_msgs.map((item) => (
              <div className="list-group" key={item}>
                <a
                  href="javascript:void(0);"
                  className="list-group-item list-group-item text-dark"
                >
                  <FiberManualRecordIcon /> {item}
                </a>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default Notifications;
