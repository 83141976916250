import React from 'react'
import BaseRouter from './routes';

function App() {
  // window.open ("http://localhost:3000/test","mywindow","status=1,toolbar=0");

  return (
    <div className="App">
      <BaseRouter />
    </div>
  );
}

export default App;
