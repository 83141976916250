import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import SelectCategory from "../components/StudentDashboard/SelectCategory";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { getLinks, readUser4Client, useAuthState } from "../context";
import { getCategories4Client } from "../context";
import Background from "../images/subjectsbackground.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StudentDashboard() {
  const [{}, dispatch] = useAuthState();
  const [open, setOpen] = React.useState(false);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const level = parseInt(localStorage.getItem("user.level"));
  var notifications;
  JSON.parse(localStorage.getItem("notifications"))
    ? (notifications = JSON.parse(localStorage.getItem("notifications")))
    : (notifications = []);

  useEffect(() => {
    getLinks(dispatch);
    readUser4Client(dispatch);
    getCategories4Client(dispatch, level);
    try {
      localStorage.removeItem("testid");
      localStorage.removeItem("testtime");
      localStorage.removeItem("testname");
      localStorage.removeItem("totalMarks");
      localStorage.removeItem("timer");
    } catch (err) {
      console.log(err);
    }

    const status = localStorage.getItem("isViewed");
    if (!status) handlePopUp();
  }, []);

  const handlePopUp = () => {
    setTimeout(() => {
      JSON.parse(localStorage.getItem("notifications"))
        ? (notifications = JSON.parse(localStorage.getItem("notifications")))
        : (notifications = []);
      const status = localStorage.getItem("isViewed");
      if (notifications.length > 0 || !status) setOpen(true);
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("isViewed", true);
  };

  if (!isAuthenticated || level === "3" || level === "1")
    return <Redirect to="/login" />;
  return (
    <div
      style={{
        backgroundImage: `url("${Background}")`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <SelectCategory />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"New Messages"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {notifications.length === 0 ? (
              <h4 className="col-sm-12 text-center text-secondary border border-info p-3 mt-5">
                No Notifications Available!
              </h4>
            ) : (
              notifications.map((item) => (
                <div className="list-group" key={item}>
                  <a
                    href="javascript:void(0);"
                    className="list-group-item list-group-item text-dark"
                  >
                    <FiberManualRecordIcon /> {item}
                  </a>
                </div>
              ))
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
