import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  deleteQuestion,
  getSections,
  updateQuestion,
  useAuthState,
  viewQuestions,
} from "../../context";
import Skeleton from "@material-ui/lab/Skeleton";
import { Card, CardActions, CardContent, Divider, Drawer } from "@material-ui/core";
import UpdateQn from "./UpdateQn";
import SimpleNav from "../SimpleNav";
import History from "../History";
import AddQns from "./AddQns";
import AddSection from "./AddSection";
import { Redirect } from "react-router-dom";
import UpdateSection from "./UpdateSection";
import DeleteItem from "../SubAdminDashboard/DeleteItem";
import parse from 'html-react-parser'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColoe: "#ffff",
  },
  card: {
    boxShadow: "3px 8px 8px #a3b1c4",
    margin: "3px 0 4px 4px",
  },
}));

export default function ViewQuestions() {
  const testname = localStorage.getItem("testname");
  const level = localStorage.getItem("user.level");
  const [load, setLoad] = useState(true);

  const [{ questions, loading }, dispatch] = useAuthState();
  const testid = localStorage.getItem("testid");
  const createdBy = localStorage.getItem("createdBy");

  useEffect(() => {
    getSections(dispatch, testid);
    viewQuestions(dispatch, testid);
    getSections(dispatch, testid);
  }, [testid]);


  const classes = useStyles();
  if (testid === undefined) return <Redirect to="/sub-admin-dashboard" />;
  return (
    <div className={classes.root}>
      <SimpleNav heading={`Questions of ${testname}`} />
      {loading & load ? (
        <>
          <div className="container mt-5">
            <div className="mt-2">
              <Skeleton variant="rect" height={65} />
              <br />
              <Skeleton variant="rect" height={165} />
            </div>
            <br />
            <div className="mt-2">
              <Skeleton variant="rect" height={65} />
              <br />
              <Skeleton variant="rect" height={165} />
            </div>
            <br />
            <div className="mt-2">
              <Skeleton variant="rect" height={65} />
              <br />
              <Skeleton variant="rect" height={165} />
            </div>
            <br />
          </div>
        </>
      ) : (
        <Grid container spacing={3} className="mt-5">
          <div className="ml-5 mt-5">
            <History history={testname} />
          </div>
          <div className="mt-2 mb-2 d-flex mt-5">
            <div className="ml-4">
              <UpdateSection createdBy={createdBy} />
            </div>
            {level === "1" ? (
              <div className="ml-5">
                <AddSection
                  text={"Add Section"}
                  section={""}
                  title={"Create a new section"}
                  sectionid={""}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {questions.filter((qn) => qn.isActive === 1).length === 0 ? (
            <h2 className="col-sm-12 text-center text-secondary border border-info p-3 mt-4">
              No Questions!
            </h2>
          ) : (
            <>
              {questions.map(
                ({
                  question,
                  title,
                  qid,
                  type,
                  optionA,
                  optionB,
                  optionC,
                  optionD,
                  correctAns,
                  marks,
                  section,
                  isActive,
                  content,
                  img,
                }) => (
                  <Grid item xs={12} key={qid}>
                    <Card className={classes.card}>
                      {isActive === 1 ? (
                        <>
                          <CardContent>
                            {type !== 3 ?
                              img && img !== "" ? (
                                <img
                                  src={img}
                                  className="mx-auto d-block"
                                  style={{ maxHeight: '550px', maxWidth: '75%'}}
                                  alt="img"
                                />
                              ) : (
                                  <>{parse(question)}</>
                              ):
                              title}
                                
                            <div className="d-flex justify-content-between mt-4 mb-3">
                            <Drawer />

                              {marks > 0 ? (
                                <Typography variant="body1" className="ml-4">
                                  <b>Marks:</b> {marks}
                                </Typography>
                              ) : null}
                              {section && section !== null ? (
                                <Typography variant="body1" className="mr-4">
                                  <b>Section:</b> {section}
                                </Typography>
                              ) : null}
                            </div>

                            <Divider />
                            {type === 1 ? (
                              <div className="container mt-3">
                                <Typography component="p">
                                  (a) {optionA}
                                </Typography>
                                <Typography component="p">
                                  (b) {optionB}
                                </Typography>
                                <Typography component="p">
                                  (c) {optionC}
                                </Typography>
                                <Typography component="p">
                                  (d) {optionD}
                                </Typography>
                                <Typography>Ans: {correctAns}</Typography>
                              </div>
                            ) : type === 3 ? (
                              content?
                              <div className="container mt-3">
                                <Typography component="p">{parse(content)}</Typography>
                              </div>
                              :
                              <img
                                src={img}
                                className="mx-auto d-block"
                                style={{ maxHeight: '550px', maxWidth: '75%'}}
                                alt="img"
                              />
                            ) : type === 4 ? (
                              <div className="container mt-3">
                                 <Typography>Ans: {correctAns}</Typography>
                              </div>
                            ) : null }
                            <CardActions className="float-right mr-3 mb-3">
                              <UpdateQn
                                iquestion={question}
                                ioptionA={optionA}
                                ioptionB={optionB}
                                ioptionC={optionC}
                                ioptionD={optionD}
                                icorrectAns={correctAns}
                                ititle={title}
                                icontent={content}
                                type={type}
                                qid={qid}
                                imarks={marks}
                                setLoad={setLoad}
                                isection={section}
                                iimg={img ? img : null}
                                updateQuestion={
                                    updateQuestion
                                }
                              />
                              <DeleteItem
                                deleteFun={() =>
                                  deleteQuestion(dispatch, qid, testid, marks)
                                }
                                item={question ? parse(question) : title ? parse(title) : "the question"}
                              />
                            </CardActions>
                          </CardContent>
                        </>
                      ) : null}
                    </Card>
                  </Grid>
                )
              )}
            </>
          )}
        </Grid>
      )}
      {level === "1" ? <AddQns setLoad={setLoad} /> : <></>}
    </div>
  );
}
