import React, { useState } from "react";
import { subAdminLogin, useAuthState } from "../context";
import { useForm } from "react-hook-form";
import "./Login.css";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link, Redirect } from "react-router-dom";
import { Loading } from "../components/Loading";



export default function SubAdminLogin() {
  const { register, handleSubmit, errors } = useForm();
  const[type, setType] = useState("password")

  const [{ loading, errorMessage }, dispatch] = useAuthState()
  const isAuthenticated = localStorage.getItem("isAuthenticated")
  const level = localStorage.getItem("user.level")

  const onSubmit = (data, e) => {
    e.preventDefault();
    subAdminLogin(dispatch, data);
  };

  if (isAuthenticated && level === "1") return <Redirect to="/sub-admin-dashboard" />
  else
  return (
    <div className="wrapper">


    <div className="login-box">
      <i className="fas fa-user-circle fa-4x head"></i>

      <h1 className="text-center">Sub Admin Login</h1>

      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div className="form-group textbox">
          <i className="fas fa-envelope"></i>
          <input
            required
            id="inputForEmail"
            name="username"
            autoComplete="off"
            type="email"
            className="form-control input"
            aria-describedby="Enter email address"
            placeholder="Enter email address"
            ref={register({
              required: {
                value: true,
                message: "Please enter Your Email",
              },
            })}
          />

          {errors.username && (
            <span className="errorMessage text-capitalize">
              <ErrorOutlineIcon /> {errors.username.message}
            </span>
          )}
        </div>
        <div className="form-group textbox">
          <i className="fas fa-lock"></i>
          <input
            type={type}
            name="password"
            autoComplete="off"
            className="form-control input"
            id="inputForPassword"
            placeholder="Enter password"
            ref={register({
              required: {
                value: true,
                message: "Please  Enter Your password",
              },
            })}
          />{type === "password"? <span className="fa fa-eye float-right mt-n4 mb-n3 mr-4" type="button" onClick={()=>{setType("text")}}></span>:<span className="fa fa-eye-slash float-right mt-lg-n4 mb-n3 mr-4" type="button"  onClick={()=>{setType("password")}}></span>}

          {errors.password && (
            <span className="errorMessage text-capitalize">
              <ErrorOutlineIcon /> {errors.password.message}
            </span>
          )}
        </div>
        {errorMessage ? (
          <p className="lead text-danger">{errorMessage}</p>
        ) : (
          <p></p>
        )}
        <div className="d-flex align-items-center mt-3">
          <button type="submit" className="btn btn-primary btns">
          {loading? <Loading />: "Login"}
          </button>
        </div>
        <div className="links">
          <Link to="/"> {"Forgot Password?"}</Link>
          <br />
          
        </div>
      </form>
    </div>
    </div>
  );
}
