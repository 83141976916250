import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/context';
import {reducer, initialState} from "../src/context/reducer"

ReactDOM.render(
    <AuthProvider initialState={initialState} reducer={reducer}>
      <App />
    </AuthProvider>,
  document.getElementById('root')
  
);

reportWebVitals();
