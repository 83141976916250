import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLoading } from './Loading';
import { useAuthState } from '../context';
import Timer from "./StudentDashboard/Timer";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
  },
}));

export default function SimpleNav({heading}) {
  const classes = useStyles();
  const [{ loading, questions }] = useAuthState();


  return (
    <div className="fixed-top row mb-5">
    <div className={classes.root} >
      <AppBar position="static" className="p-0">
        <Toolbar variant="dense">
          <div  className="col-sm-8">
            <h6>{heading}</h6>
          </div>
          <div  className="col-sm-4 text-right align-content-xl-end">
        {questions.length === 0 ? null : <Timer/> }
          </div>
        </Toolbar>
        {loading ? <NavLoading /> : <></>}
      </AppBar>
    </div>
    </div>
  );
}
